import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';
import {listen} from '@exadel/esl/modules/esl-utils/decorators';
import {ESLResizeObserverTarget} from '@exadel/esl/modules/esl-utils/dom/events';
import {memoize} from '@exadel/esl/modules/esl-utils/decorators/memoize';

export class CtaGroupMixin extends ESLMixinElement {
  static override is = 'cta-group-mixin';

  @memoize()
  public get $ctas(): HTMLElement[] {
    return Array.from(this.$host.querySelectorAll('.cta')) as HTMLElement[];
  }

  protected set size([width, height]: [number | string, number | string]) {
    if (typeof width === 'number') width = width + 'px';
    if (typeof height === 'number') height = height + 'px';
    this.$host.style.setProperty('--width', width);
    this.$host.style.setProperty('--height', height);
  }

  protected override connectedCallback(): void {
    super.connectedCallback();
    this.normalizeSize();
  }

  protected override disconnectedCallback(): void {
    super.disconnectedCallback();
    this.$host.style.removeProperty('--width');
    this.$host.style.removeProperty('--height');
  }

  protected max(property: 'width' | 'height'): number {
    const rects = this.$ctas.map((cta) => cta.getBoundingClientRect());
    const sizes = rects.map((rect) => rect[property]);
    return Math.max(...sizes);
  }

  protected normalizeSize() {
    this.size = ['auto', 'auto'];
    this.size = [this.max('width'), this.max('height')];
  }

  @listen({event: 'resize', target: ESLResizeObserverTarget.for})
  protected onResize(): void {
    this.normalizeSize();
  }
}

export default {
  initialize: () => CtaGroupMixin.register()
};

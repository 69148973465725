/* eslint-disable no-console */
export const DEBUG_LOG_ENABLED = (() => {
  const {hostname, search} = window.location;
  const DEV_DOMAINS = [
    '127.0.0.1', // Localhost
    'localhost', // Localhost
    'localhost.hpe.com', // Localhost
    'origin-qa-www-hpe-com.ext.hpe.com', // QA
    'origin-dev-www-hpe-com.ext.hpe.com' // DEV
  ];
  // Replace with 'intersect' check in case of more domains
  return (DEV_DOMAINS.indexOf(hostname) !== -1) || (search.indexOf('uidebug=') !== -1);
})();

const ERROR_LOGGER = console.error ? 'error' : 'log';
const DEBUG_LOGGER = console.debug ? 'debug' : 'log';

const loggers = new Map<string, Logger>();

interface Buffer extends Array<any> {
  0: string;
}

export class Logger {
  protected buffer: Buffer[] = [];
  protected currentGroup: string;
  protected cleanBufferTimeout: number;

  constructor(
    protected prefix: string,
  ) {
    // Binding API methods to simplify usage
    this.log = this.log.bind(this);
    this.debug = this.debug.bind(this);
    this.error = this.error.bind(this);
  }

  protected createMessage(msg: string | Error) {
    const now = (new Date()).toLocaleTimeString();
    return `${this.prefix} (${now}) ${msg}`;
  }

  protected appendToBuffer(msg: string, ...args: any[]) {
    const prefix = msg.split(':')[0];
    this.buffer.push([this.createMessage(msg), ...args]);

    if (this.currentGroup && this.currentGroup !== prefix) {
      this.flushBuffer();
    } else {
      clearTimeout(this.cleanBufferTimeout);
      this.cleanBufferTimeout = window.setTimeout(() => this.flushBuffer(), 1000);
    }
    this.currentGroup = prefix;
  }

  protected flushBuffer() {
    if (!this.buffer.length) return;

    const isOne = this.buffer.length === 1;
    const name = `${this.prefix} ${this.currentGroup}: ...`;

    (console.groupCollapsed && !isOne) && console.groupCollapsed(name);
    this.buffer.forEach((args) => console[DEBUG_LOGGER](...args));
    (console.groupEnd && !isOne) && console.groupEnd();

    // Reset buffer
    this.buffer = [];
    this.currentGroup = null;
  }

  /** Print error message */
  public error(msg: string, ...args: any[]) {
    console[ERROR_LOGGER](this.createMessage(`Error: ${msg}`), ...args);
  }

  /** Print debug information */
  public debug(msg: string, ...args: any[]) {
    if (!DEBUG_LOG_ENABLED) return;
    this.appendToBuffer(msg, ...args);
  }

  /** Print common information */
  public log(msg: string, ...args: any[]) {
    console.log(this.createMessage(msg), ...args);
  }

  /** Returns cached logger for prefix */
  static for(prefix: string) {
    if (loggers.has(prefix)) return loggers.get(prefix);
    const log = new Logger(prefix);
    loggers.set(prefix, log);
    return log;
  }
}

const DEFAULT_PREFIX = '[HPE]';
export const error = Logger.for(DEFAULT_PREFIX).error;
export const debug = Logger.for(DEFAULT_PREFIX).debug;
export const log = Logger.for(DEFAULT_PREFIX).log;
export default Logger.for(DEFAULT_PREFIX).log;
/* eslint-enable no-console */

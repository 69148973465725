import {initComponent} from 'core/helpers/component';

export default {
  initialize: async ($: any) => {
    if (!$('.portfolio, .portfolio-vertical-carousel, .offering').length) return;

    const Portfolio: any = await import(/*webpackChunkName: "design-3.0/portfolio"*/ 'design-3.0/portfolio');
    initComponent(Portfolio, $('.portfolio'));
  }
};


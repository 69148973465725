var MODULE_ALIAS = 'core/carousel'; 
import {ARROW_LEFT, ARROW_RIGHT, isRelativeNode} from '@exadel/esl/modules/esl-utils/dom';
import {debounce} from '@exadel/esl/modules/esl-utils/async/debounce';

import View from 'core/view';
import {unwrap} from 'core/helpers/dom';
import {isA11yMode} from 'core/helpers/focus';
import {deepMerge} from 'core/helpers/object';
import {createDocumentTouchMoveHandler} from 'core/helpers/scroll';
import {BREAKPOINTS, parseViewportItems} from 'core/helpers/breakpoints';

import './libs/owl.carousel.js';
import './libs/owl.navigation.js';

const CFG_KEY_MAPPER = (bp) => BREAKPOINTS[bp].min;
const CFG_VALUE_MAPPER = (bp, value) => ({items: parseInt(value)});

const touchMoveHandler = createDocumentTouchMoveHandler();
const preventDefaultListener = function (e) {
  e.preventDefault();
};

class Carousel extends View { 
        static className = MODULE_ALIAS + '#Carousel';
         
      
  static defaults = Object.assign({}, View.defaults, {
    pluginContainer: '.owl-carousel',
    wrapper: null,
    pluginCfg: {
      navText: [
        '<span class="icon-arrow-left"></span>',
        '<span class="icon-arrow-right"></span>'
      ],
      mouseDrag: false,
      pullDrag: false,
      slideBy: 'page',
      rewind: true
    },
    responsive: {},
    viewportItems: null,
    items: '.carousel-item',
    mode: 'carousel',
    readyClass: 'is-ready',
    oneItemOnlyClass: 'one-item-only',
    noItemsClass: 'no-items',
    loop: false,
    noArrowsNav: false,
    noDotsNav: false,
    prev: 'prev',
    next: 'next',
    dot: 'dot',
    dotLabel: 'Carousel page',
    notesUpdateTimeout: 500
  });

  static attrsOptionsMap = {
    '.no-arrows': 'noArrowsNav',
    '.no-dots': 'noDotsNav'
  };

  static dataAttrs = 'carousel';

  init() {
    this.$items = this.find(this.options.items);
    this.$pluginContainer = this.find(this.options.pluginContainer);
    this.options.wrapper = this.options.wrapper ? this.$el.closest(this.options.wrapper) : this.$el;
    this.updateClonedNotesDebounced = debounce(this.updateClonedNotes.bind(this), this.options.notesUpdateTimeout);
    this.processOriginalNotes();

    let {items, noItemsClass, oneItemOnlyClass} = this.options;
    switch (items.length) {
      case 0:
        this.addClass(noItemsClass);
        this.onReadyState();
        break;
      case 1:
        this.addClass(oneItemOnlyClass);
        this.initCarouselPlugin();
        break;
      default:
        this.initCarouselPlugin();
    }
  }

  '{items} on:esl:show:request'(e) {
    this.onShowTooltipArea(e.currentTarget);
  }

  onShowTooltipArea(target) {
    if (this.plugin) {
      let position = -1;
      for (let i = 0; i < this.plugin._items.length; i++) {
        if (this.plugin._items[i].get(0).contains(target)) {
          position = i;
        }
      }
      if (position > -1) {
        const _speed = 0;
        this.plugin.to(position, _speed, true);
        this.plugin.to(position, _speed, true); //NOTE: make it twice to scroll to not cloned item
      }
    }
  }

  /** Parses original note element and forces them to fire event on index update */
  processOriginalNotes() {
    if (!this.$items.length) return;
    this.$originalNotes = [...this.$items.find('hpe-note')];
    this.$originalNotes.map(($note) => $note.trackUpdate = true);
  }

  /** Updates cloned standalone notes labels */
  updateClonedNotes() {
    if (!this.$originalNotes) return;
    const $cloned = [...this.find('.owl-item.cloned hpe-note')];
    $cloned.forEach(($note) => {
      const $originalNote = this.$originalNotes.find(($el) => $el.html === $note.html);
      if (!$originalNote) return;
      $note.standaloneLabel = $originalNote.renderedIndex;
    });
  }

  initCarouselPlugin() {
    this.currentOptions = this.pluginOptions;
    this.$pluginContainer.owlCarousel(this.currentOptions);
  }

  get plugin() {
    return this._plugin || (this._plugin = this.$pluginContainer.data('owl.carousel'));
  }

  get pluginOptions() {
    let dynamicOptions = {};
    if (this.options.mode === 'slider') {
      dynamicOptions.items = 1;
      dynamicOptions.noClones = true;
    }

    let {viewportItems, noArrowsNav, noDotsNav, loop} = this.options;
    let firstItem = this.$items.filter('[data-first]:first');
    dynamicOptions.startPosition = firstItem.length ? this.$items.index(firstItem) || 0 : 0;
    dynamicOptions.nav = !noArrowsNav;
    dynamicOptions.dots = !noDotsNav;
    dynamicOptions.loop = loop;

    Object.assign(dynamicOptions, {
      onDrag: this.onDrag.bind(this),
      onDragged: this.onDragged.bind(this)
    });

    if (viewportItems) {
      dynamicOptions.responsive = parseViewportItems(viewportItems, CFG_KEY_MAPPER, CFG_VALUE_MAPPER);
    }

    mergeResponsiveConfig(dynamicOptions, this.options.responsive);

    dynamicOptions = deepMerge(this.options.pluginCfg, dynamicOptions);

    disableTouchIfItemsIsNotEnough(dynamicOptions, this.$items);

    return dynamicOptions;
  }

  get size() {
    return this.$items && this.$items.length || 0;
  }

  get inTransition() {
    return this.hasClass('transition');
  }

  /**
   * @returns {Number} of normalized current index
   * */
  get currentIndex() {
    return this.plugin ? this.plugin.relative(this.plugin.current()) : 0;
  }

  /**
   * @returns {JQuery} current owl-slide element
   * */
  get currentSlide() {
    return this.plugin && this.plugin.items(this.currentIndex);
  }

  /**
   * @returns {JQuery} active (including copies) owl-slide element
   * */
  get activeSlides() {
    if (!this.plugin) return $([]);
    return this.plugin.$stage.children('.active');
  }

  /**
   * @returns {number} of active items
   * */
  get activeCount() {
    return this.plugin ? this.plugin.minimum() : 0;
  }

  /**
   * @returns {JQuery} first focusable element or slide on "owl stage"
   * */
  get firstFocusable() {
    const slide = this.activeSlides.first();
    if (slide.is('[tabindex]')) return slide;
    return slide.find('*').filter(':visible').filter('a, button, iframe, :input, [tabindex]').first();
  }

  /**
   * @returns {boolean} reset focus to slides start allowed
   * */
  get allowFocusReset() {
    if (!isA11yMode()) return false;
    return document.activeElement === this._lastFocused ||
      document.activeElement === null || document.activeElement === document.body;
  }

  '{pluginContainer} on:initialized.owl.carousel'() {
    setTimeout(() => {
      this.addClass(this.options.readyClass);
      this.initControlsAnalytics();
      this.onReadyState();
    }, 5);
    setTimeout(() => this.initA11y(), 10);
  }

  '{pluginContainer} on:changed.owl.carousel.internal'(event) {
    if (event.property.name === 'position') {
      this.onSlideChanged();
      this.updateA11y();
    }
  }

  '{pluginContainer} on:refreshed.owl.carousel.internal'() {
    this.initControlsAnalytics();
    this.updateA11y();
  }

  // a11y
  '{pluginContainer} on:translate.owl.carousel.a11y'() {
    this.addClass('transition');
    this._resetFocusTm && clearTimeout(this._resetFocusTm);
    this._lastFocused = document.activeElement;
  }

  '{pluginContainer} on:translated.owl.carousel.a11y'() {
    setTimeout(() => this.removeClass('transition'), 100);
    this._resetFocusTm && clearTimeout(this._resetFocusTm);
    this._resetFocusTm = setTimeout(() => {
      this.allowFocusReset && this.firstFocusable.focus();
      this._lastFocused = null;
    }, 750);
  }

  'on:keydown.a11y'(e) {
    switch (e.key) {
      case ARROW_LEFT:
        !this.inTransition && this.prev();
        break;
      case ARROW_RIGHT:
        !this.inTransition && this.next();
        break;
    }
  }

  'on:hpe:note:update'(e) {
    this.updateClonedNotesDebounced();
    e.stopImmediatePropagation();
  }

  '{window} on:esl:refresh'(e) {
    if (!isRelativeNode(unwrap(this.$el), unwrap(e.target))) return;
    this.plugin.refresh();
  }

  // Updates original notes collection and cloned notes
  '{pluginContainer} on:force:hpe:notes:update'() {
    this.$originalNotes = [...this.$items.find('hpe-note')];
    this.updateClonedNotesDebounced();
  }

  /** Slides to the specified position */
  to(position) {
    this.plugin.to(position, undefined, '.');
  }

  next() {
    this.plugin && this.plugin.next();
  }

  prev() {
    this.plugin && this.plugin.prev();
  }

  // Callback hooks
  /**
   * Called once carousel initialized
   * */
  onReadyState() {
  }

  /**
   * Called on slide change happened
   * */
  onSlideChanged(e) {
    this.$items.find('.js-embedded-video').trigger('stop'); // Stop all videos
    this.$el.triggerHandler('slidechanged.carousel', e);
  }

  onDrag() {
    touchMoveHandler.disable();
    this.$el[0].addEventListener('touchmove', preventDefaultListener, {passive: false});
  }

  onDragged() {
    touchMoveHandler.enable();
    this.$el[0].removeEventListener('touchmove', preventDefaultListener);
  }

  updateCurrentOptions(config) {
    const responsiveCfg = parseViewportItems(config.viewportItems, CFG_KEY_MAPPER, CFG_VALUE_MAPPER);
    updateOptionsResponsiveItems(this.currentOptions, responsiveCfg);
    disableTouchIfItemsIsNotEnough(this.currentOptions, this.$items, true);
    this.$pluginContainer.trigger('refresh.owl.carousel');
  }

  addItems(items) {
    [].concat(items).forEach((item) => {
      this.$pluginContainer.trigger('add.owl.carousel', [item]);
    });

    // update items & options
    this.$items = this.find(this.options.items);
    disableTouchIfItemsIsNotEnough(this.currentOptions, this.$items, true);

    this.$pluginContainer.trigger('refresh.owl.carousel');
  }

  clearItems() {
    const length = this.$pluginContainer.find('.owl-item:not(.cloned)').length;
    for (let i = 0; i < length; i++) {
      this.$pluginContainer.trigger('remove.owl.carousel', [i]).trigger('refresh.owl.carousel');
    }
  }

  replaceItems(items) {
    this.clearItems();
    this.addItems(items);
  }

  initControlsAnalytics() {
    const regionIDAttr = 'data-analytics-region-id';
    const regionID = this.$el.closest(`[${regionIDAttr}]`).attr(regionIDAttr);

    if (!regionID) return;

    this.find('.owl-prev').attr(regionIDAttr, `${regionID}|${this.options.prev || 'prev'}`);
    this.find('.owl-next').attr(regionIDAttr, `${regionID}|${this.options.next || 'next'}`);
    this.find('.owl-dot').attr(regionIDAttr, `${regionID}|${this.options.dot || 'dot'}`);
  }

  initA11y() {
    const {$el} = this;
    $el.attr('role', $el.attr('role') || 'group');
    $el.attr('tabindex', $el.attr('role') || '0');
    $el.attr('aria-label', $el.attr('role') || 'Carousel');

    this._a11yReady = true;
    this.updateA11y();
    this.addClass('a11y');
  }

  updateA11y() {
    if (!this._a11yReady) return;
    this.updateDotsA11y();
    this.refreshClonedNav();
  }

  updateDotsA11y() {
    const dotFocused = $(document.activeElement).is('.owl-dot');
    this.find('.owl-dots').attr('role', 'tablist');
    this.find('.owl-dot').each((index, item) => {
      const $item = $(item);
      const isActive = $item.is('.active');
      const label = this.options.dotLabel + ' ' + ($item.index() + 1);
      $item.attr('role', 'tab')
        .attr('aria-selected', String(isActive))
        .attr('aria-label', label);
      if (isActive && dotFocused) {
        $item.focus();
        this._lastFocused = $item.get(0);
      }
    });
  }

  refreshClonedNav() {
    const originalNav = this.find('.owl-nav:not(.cloned)');
    const clonedNav = this.find('.owl-nav.cloned');
    if (clonedNav.length) {
      clonedNav.prop('className', originalNav.prop('className')).addClass('cloned');
      clonedNav.find('.owl-prev').prop('className', originalNav.find('.owl-prev').prop('className'));
      clonedNav.find('.owl-next').prop('className', originalNav.find('.owl-next').prop('className'));
    } else {
      const navCopy = originalNav.clone(true).addClass('cloned');
      this.find('.owl-carousel').prepend(navCopy);
    }
  }
}

/**
 * Applies customized responsive config
 * @param {object} result - accumulating object
 * @param {object} responsiveConfig - customized responsive config. Format {480: {option: value}, sm: {option: value}}
 * @returns {{responsive}|*}
 */
function mergeResponsiveConfig(result, responsiveConfig = {}) {
  let responsive = result.responsive || {};
  for (let key in responsiveConfig) {
    if (BREAKPOINTS[key]) {
      const val = BREAKPOINTS[key].min;
      responsive[val] = Object.assign(responsive[val] || {}, responsiveConfig[key]);
    } else {
      responsive[key] = Object.assign(responsive[key] || {}, responsiveConfig[key]);
    }
  }
  result.responsive = responsive;
  return result;
}

function disableTouchIfItemsIsNotEnough(options, items, isUpdate = false) {
  const {length} = items;
  if (options.responsive) {
    for (let size in options.responsive) {
      let config = options.responsive[size];
      config.touchDrag = config.items < length;
      if (isUpdate) {
        config.loop = options.loop;
      }
      if (options.loop && length <= config.items) {
        config.loop = false;
      }
    }
  }
}

function updateOptionsResponsiveItems(options, newOptions) {
  if (!options.responsive) return;
  for (let size in options.responsive) {
    options.responsive[size].items = newOptions[size].items;
  }
}

export default Carousel;


;exports.default.componentName = MODULE_ALIAS;
import {ESLEventUtils} from '@exadel/esl/modules/esl-utils/dom/events';
import {ModalForm} from './modal-form';
import ModalFormTrigger from './modal-form-trigger';

export default {
  initialize: () => {
    if (customElements.get(ModalForm.is)) return;

    ModalForm.register();
    ModalFormTrigger.register();

    ESLEventUtils.subscribe(
      document.body,
      'hpe:modal-form:open',
      (e: CustomEvent) => ModalForm.open(e.detail)
    );
  }
};

import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';
import {listen} from '@exadel/esl/modules/esl-utils/decorators';
import {SharedAPI} from 'cockpit/api/shared';
import {debug} from 'core/log';
import {getConfig} from 'core/helpers/config';
import {MetricsMyAccount} from 'core/helpers/metrics-my-account';

/**
 * Mixin to trigger AEM logout service
 *
 * We should pass redirectURL param to AEM service.
 * In case when AEM defines pagePath in the config
 * we use this value or window.location.href as fallback.
 * */

export class LogoutMixin extends ESLMixinElement {
  public static is = 'logout-trigger';

  protected override connectedCallback(): void {
    super.connectedCallback();
    if (this.$host.tagName === 'A') {
      this.$$attr('href', 'javascript:void(0)');
      this.$$attr('target', null);
      this.$$attr('role', 'button');
    }
  }

  public get redirectURL(): string {
    const pagePath = getConfig('pagePath');
    return pagePath || window.location.href;
  }

  @listen('click')
  protected async onClick(): Promise<void> {
    const {redirectURL} = this;
    const authAPI = await SharedAPI.auth$;

    const user = await authAPI.isUserLogged().catch(() => null);
    // HPED-27694: force page reload as logout for an unauthorized user
    if (!user) return window.location.reload();


    debug(`LOGOUT: ${user.providers.join(',')}`);
    MetricsMyAccount.unlockSignInSuccessMutex();
    authAPI.logout({redirectURL});
  }
}

export default {
  initialize: () => LogoutMixin.register()
};

var MODULE_ALIAS = 'styleguide/hpe-card'; 
import {ENTER, SPACE} from '@exadel/esl/modules/esl-utils/dom/keys';

export function initialize() {
  document.body.addEventListener('keydown', (e) => {
    const target = e.target;
    if (!(target && target.classList.contains('hc-learn-more-link'))) return;
    if ([ENTER, SPACE].includes(e.key)) {
      const card = e.target.closest('.hpe-card');
      if (!card || card.attr('hpe-video-overlay')) return;
      card && card.click();
      e.stopPropagation();
    }
  }, {capture: true});
}

export default {initialize};

;exports.default.componentName = MODULE_ALIAS;
/**
 * Adds custom CSS classes to the <body> tag with browser information
 */
import Bowser from 'bowser';
import {debug} from 'core/log';

import {hasHover} from '@exadel/esl/modules/esl-utils/environment/device-detector';

const trimToDigit = (str: string) => str.replace(/^[^\d]+/, '');

const ua = window.navigator.userAgent;
const browserParser = Bowser.getParser(ua);

// Main detection cache
export const browserName = browserParser.getBrowserName() || '';
export const browserNameClass = browserName.toLowerCase().replace(/ /g, '-');
export const browserVersion = browserParser.getBrowserVersion() || '';
export const browserVersionMajor = parseInt(trimToDigit(browserVersion), 10);
export const browserEngine = browserParser.getEngine().name || '';
export const osName = browserParser.getOSName(true) || '';
export const osVersion = browserParser.getOSVersion() || '';
export const osVersionMajor = parseInt(trimToDigit(osVersion), 10);
export const platformType = browserParser.getPlatformType(true) || '';

const ENGINE_CLASS_MAPPING: Record<string, string> = {
  webkit: 'engine-webkit',
  blink: 'engine-blink',
  gecko: 'engine-gecko',
  edgehtml: 'ms-edge'
};
export const browserEngineClass = ENGINE_CLASS_MAPPING[browserEngine.toLowerCase()];

// Custom Detection
export const isMSEdge = browserEngineClass === 'ms-edge';
export const isGecko = browserEngineClass === 'engine-gecko';
export const isWebkit = browserEngineClass === 'engine-webkit';
export const isMobile = platformType === 'mobile' || platformType === 'tablet';
export const isMobileOS = osName === 'ios' || osName === 'android';
export const isMobileSafari = osName === 'ios' && isWebkit && !ua.match(/CriOS/i);
export const isBot = /Chrome-Lighthouse|Google Page Speed Insights/i.test(ua);
export {hasHover};
// =====================================
// ======== Browser body class =========
const body = document.body;

// Mobile marker
if (isMobile) body.classList.add('on-mobile');

// Browser Name, Version, Engine
body.classList.add(browserNameClass);
if (!isNaN(browserVersionMajor)) body.classList.add(`${browserNameClass}-${browserVersionMajor}`);
if (browserEngineClass) body.classList.add(browserEngineClass);

// OS markers
if (isMobileOS) {
  body.classList.add(osName);
  if (!isNaN(osVersionMajor)) body.classList.add(`${osName}-${osVersionMajor}`);
}

// Hover marker
if (hasHover) body.classList.add('has-hover');

debug('Device detection: ', {
  browserName,
  browserVersion,
  browserVersionMajor,
  browserEngine,
  osName,
  osVersion,
  platformType,
  isBot,
  isMobile,
  isMobileOS
});

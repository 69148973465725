import {memoize} from '@exadel/esl/modules/esl-utils/decorators';
import {ESLEventUtils} from '@exadel/esl/modules/esl-utils/dom/events';
import {ESLMediaQuery} from '@exadel/esl/modules/esl-media-query/core';

import {unwrap} from 'core/helpers/dom';
import {getExtremeItemsProp} from 'core/helpers/dom-sizes';
import {SlideCarousel, SlideCarouselOptions} from 'core/slide.carousel';

import {Theme, setHeaderTheme} from 'core/helpers/themes';

export interface SmartMarqueeOptions extends SlideCarouselOptions {
  slideImage: string;
  arrowsContainer: string;
  isStandalone: boolean;
  isOverlapping: boolean;
}

export class SmartMarqueeCarousel extends SlideCarousel {
  public static defaults: SmartMarqueeOptions = {
    ...SlideCarousel.defaults,
    propagateTheme: true,
    refreshCtaGroup: true,
    isStandalone: false,
    isOverlapping: false,
    slideImage: '.smt-image-container',
    arrowsContainer: '.smt-carousel-controls'
  };

  public static attrsOptionsMap: Record<string, string> = {
    ...SlideCarousel.attrsOptionsMap,
    '.standalone': 'isStandalone',
    '.marquee-underlap': 'isOverlapping'
  };

  public options: SmartMarqueeOptions;

  @memoize()
  protected get $arrowsContainer(): HTMLElement {
    return unwrap(this.find(this.options.arrowsContainer));
  }

  protected get $inner(): HTMLElement {
    return unwrap(this.$innerArea);
  }

  protected get activeImageHeight(): number {
    return this.$active.find(this.options.slideImage).height() || 0;
  }

  protected override applyTheme(theme: Theme) {
    super.applyTheme(theme);
    this.options.isOverlapping && setHeaderTheme(theme);
  }

  public override updateSliderHeight(): void {
    if (this.size <= 1) return;
    // reset slide area height
    this.$inner.style.removeProperty('height');
    const height = getExtremeItemsProp(this.$items, 'innerHeight');

    this.setSliderHeight(height);
    this.alignArrows(height);
  }

  protected setSliderHeight(height: number): void {
    this.$inner.style.height = `${height}px`;
    ESLEventUtils.dispatch(this.$inner, 'esl:refresh');
  }

  /** Aligns carousel arrows container by the slide text content. */
  protected alignArrows(slideHeight?: number): void {
    if (!ESLMediaQuery.for('@-SM').matches) return;
    if (!slideHeight) {
      this.$arrowsContainer.style.removeProperty('top');
      return;
    }
    const contentHeight = slideHeight - this.activeImageHeight;
    this.$arrowsContainer.style.top = `${slideHeight - contentHeight / 2}px`;
  }
}

export default SmartMarqueeCarousel;

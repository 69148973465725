import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';
import {attr, listen} from '@exadel/esl/modules/esl-utils/decorators';

import {GenericModalService} from 'core/generic-modal-service';
import {SharedAPI} from 'cockpit/api/shared';

import type {AuthProviderType} from 'cockpit/api/auth/auth-api';

export class LoginIAMMixin extends ESLMixinElement {
  public static is = 'login-iam-trigger';

  @attr({dataAttr: true}) public afterLogin: string;

  protected override connectedCallback() {
    super.connectedCallback();
    if (this.$host.tagName === 'A') {
      this.$$attr('href', 'javascript:void(0)');
      this.$$attr('target', null);
      this.$$attr('role', 'button');
    }
  }

  public get redirectURL() {
    if (this.afterLogin) return this.afterLogin;
    const url = new URL(window.location.href);
    url.searchParams.delete(GenericModalService.DIRECT_MODAL_PARAM);
    url.searchParams.delete(GenericModalService.INDIRECT_MODAL_PARAM);
    return url.toString();
  }

  @listen('click')
  protected onClick(): void {
    const {redirectURL} = this;
    SharedAPI.cockpit$.then((api) => api.auth.login('hpe-okta' as AuthProviderType, {redirectURL}));
  }
}

export default {
  initialize: () => LoginIAMMixin.register()
};

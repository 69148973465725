/**
 *  Class for manipulating of url
 */
export class URLBuilder {

  url: URL;

  /**
   * Parse URL and create URLBuilder
   * @param {string} url - URL string (by default using window.location.href)
   * @returns {URLBuilder}
   */
  constructor(url: string = '') {
    if (!url || typeof url !== 'string') {
      url = window.location.href;
    }
    this.url = new URL(url);

    return this;
  }

  /**
   * Create new URLBuilder object from url string
   * @param {string} url
   * @returns {URLBuilder}
   */
  static from(url: string): URLBuilder {
    return new URLBuilder(url);
  }

  /**
   * @type {string}
   */
  set suffix(value: string) {
    if (typeof value !== 'string') return;

    const posHtml = this.url.pathname ? this.url.pathname.indexOf('.html') : -1;
    if (posHtml > 0) {
      this.url.pathname = this.url.pathname.substring(0, posHtml + 5) + value;
    }
  }

  /**
   * @type {string}
   */
  set queryString(value: string) {
    if (typeof value !== 'string') return;
    this.url.search = value;
  }

  /**
   * @type {string}
   */
  set hash(value: string) {
    if (typeof value !== 'string') return;
    this.url.hash = value;
  }

  /**
   * Set suffix value and return this object
   *
   * @param {string} value
   * @returns {URLBuilder}
   */
  setSuffix(value: string = ''): URLBuilder {
    this.suffix = value;
    return this;
  }

  /**
   * Set query string value and return this object
   *
   * @param value
   * @returns {URLBuilder}
   */
  setQueryString(value: string = ''): URLBuilder {
    this.queryString = value;
    return this;
  }

  /**
   * Set hash value and return this object
   *
   * @param {string} value
   * @returns {URLBuilder}
   */
  setHash(value: string = ''): URLBuilder {
    this.hash = value;
    return this;
  }

  /**
   * Returns a string containing the whole URL
   * @returns {string}
   */
  toString(): string {
    return this.url.toString();
  }
}

/**
 * Compile URL string from a template with placeholders
 *
 * @param {string} template - URLs template
 * @param {Object} values - object with values to replace of placeholders
 * @returns {string}
 */
export const compileURLTemplate = (template: string = '', values: Record<string, string | number | boolean> = null): string => {
  return template && values ? Object.keys(values).reduce((url, name) => {
    return url.replace(`{${name}}`, encodeURIComponent(values[name]));
  }, template) : '';
};

import ns from 'core/core';
import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';
import {attr} from '@exadel/esl/modules/esl-utils/decorators';

/**
 * BETA: common mixin-marker to execute HPE compilers on dynamically inserted markup
 * Use `hpe-compile-mixin` attribute to mark scope that needs to be processed by HPE compiler
 * As soon as compiler request is sent the `hpe-compile-mixin` is automatically removed (mixin is destroyed)
 * and a 'compiled-{timestamp}' marker class should appear instead.
 *
 * @example <div hpe-compile-mixin class="my-core-component"> ... </div>
 * */
export class HpeCompileMixin extends ESLMixinElement {
  static is = 'hpe-compile-mixin';

  @attr({name: HpeCompileMixin.is}) public scope: string;

  protected override connectedCallback() {
    ns.$compile(this.$host, this.scope || null);
    this.$$cls(`compiled-${Date.now()}`, true);
    this.$$attr(HpeCompileMixin.is, false);
  }
}

export default {
  // First init in bounds of at least next task
  initialize: () => setTimeout(() => HpeCompileMixin.register())
};

var MODULE_ALIAS = 'design-3.0/solution.portfolio.v2.init.strategy'; 
import {initComponent} from 'core/helpers/component';

export default {
  initialize: ($) => {
    $('.solution-portfolio-v2, .subtopics-vertical-carousel, .subtopic').length &&require.ensure('design-3.0/solution.portfolio.v2',
              function(require) { initComponent(require('design-3.0/solution.portfolio.v2'), null, $);;},
              function(error) { require('core/log').error("Can't load design-3.0/solution.portfolio.v2", error); },
              'design-3.0/solution.portfolio.v2'
              );
  }
};

;exports.default.componentName = MODULE_ALIAS;
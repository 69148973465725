import {ESLEventUtils} from '@exadel/esl/modules/esl-utils/dom/events';

import View, {ViewOptions} from 'core/view';
import {$window} from 'core/helpers/window';
import {rafDecorator} from 'core/helpers/dom';
import {initComponent} from 'core/helpers/component';
import {getCurrentBreakpoint} from 'core/helpers/breakpoints';
import PrivacyBannerProxy from 'core/hpe-privacy-banner';

import type {AnyToVoidFnSignature} from '@exadel/esl/modules/esl-utils/misc/functions';

export type StickyState = 'top' | 'bottom' | '';

export class StickyBox extends View {
  public static defaults: ViewOptions = {
    ns: 'stickybox',
    offsetTop: 0, // Offset from the initial top position
    offsetBottom: 0, // Offset before bottom anchor element position
    activeCls: 'sticky-active', // Active sticky state class
    activeTopCls: '', // Active Top sticky state class
    activeBottomCls: '', // Active bottom sticky state
    lateChangeCls: '', // Class to mark abrupt top state change
    lateChangeTolerance: Number.POSITIVE_INFINITY, // Tolerance in px to enable "late change" state
    bottomAnchor: '', // Element or selector or 'parent' keyword to define element to pick top state
    changeEvent: 'sticky-state-changed', // Event that triggered for the state change
    excludeBreakpoints: [] // Breakpoints where sticky effect should be disabled
  };

  protected _state: StickyState;
  protected bottomAnchorEl: JQuery<HTMLElement>;
  protected checkStateDebounced: AnyToVoidFnSignature;
  protected updateInitialStateDebounced: AnyToVoidFnSignature;
  protected elementTop: number;
  protected _enabled: boolean;
  protected windowScrollTop: number;

  public init(): void {
    this._enabled = true;

    this.elementTop = this.$el.offset().top;
    this.bottomAnchorEl = this.findBottomAnchorElement();
    this.checkStateDebounced = rafDecorator(this.checkState);
    this.updateInitialStateDebounced = rafDecorator(this.updateInitialState);

    ESLEventUtils.subscribe(this, {event: 'change', target: PrivacyBannerProxy.instance}, this.updateInitialState);
  }

  public onDestroy(): void {
    ESLEventUtils.unsubscribe(this);
    this.removeStateListener();
    super.onDestroy();
  }

  /**
   * Find bottom anchor JQuery element or returns null
   * */
  public findBottomAnchorElement(): JQuery<HTMLElement> {
    if (!this.options.bottomAnchor) {
      return null;
    }
    if (this.options.bottomAnchor === 'parent') {
      return this.$el.parent();
    }
    return $(this.options.bottomAnchor);
  }

  /**
   * Get the top position to enable sticky behaviour relatively to scroll position
   * */
  public get topLine(): number {
    return this.elementTop + this.options.offsetTop;
  }

  /**
   * Get the bottom position to enable sticky 'bottom' behaviour relatively to scroll position
   * */
  public get bottomLine(): number {
    if (this.bottomAnchorEl && this.bottomAnchorEl.length) {
      let anchorBottom = this.bottomAnchorEl.offset().top;
      if (this.options.bottomAnchor === 'parent') {
        anchorBottom += this.bottomAnchorEl.height();
      }
      return anchorBottom - this.$el.height() - this.options.offsetBottom;
    }
    return Number.POSITIVE_INFINITY;
  }

  public checkState(): void {
    const canActivate = this.canActivate();
    if (canActivate) {
      this.windowScrollTop = $window.scrollTop();
    }
    const state = canActivate ? this.calcState() : '';

    if (state !== this._state) {
      this._state = state;
      this.stateChanged();
    }
  }

  protected calcState(): StickyState {
    const top = this.topLine;
    const bottom = this.bottomLine;

    if (top < this.windowScrollTop && bottom < this.windowScrollTop) return 'bottom';
    if (top < this.windowScrollTop) return 'top';
    return '';
  }

  protected checkStateForPagePosition(top: number): Record<string, any> {
    let isActive = this.canActivate() && (this.topLine < top);
    return {
      active: isActive,
      height: isActive ? this.$el.outerHeight() : 0
    };
  }

  protected updateInitialState(): void {
    // Measure top position on cloned root element to prevent drawing artifacts on real element.
    let $positionStub = $(this.$el[0].cloneNode(false));

    $positionStub.removeClass(this.options.activeCls);
    $positionStub.removeClass(this.options.activeTopCls);
    $positionStub.removeClass(this.options.activeBottomCls);
    $positionStub.removeClass(this.options.lateChangeCls);
    $positionStub.css('height', 0);
    $positionStub.css('top', '');

    this.$el.before($positionStub as JQuery<HTMLElement>);
    this.elementTop = $positionStub.offset().top;
    $positionStub.remove();

    this.checkState();
  }

  protected stateChanged(): void {
    this.options.activeCls && this.$el.toggleClass(this.options.activeCls, this.active);
    this.options.activeTopCls && this.$el.toggleClass(this.options.activeTopCls, this.activeTop);
    this.options.lateChangeCls && this.$el.toggleClass(this.options.lateChangeCls, this.activeLateChange);
    this.options.activeBottomCls && this.$el.toggleClass(this.options.activeBottomCls, this.activeBottom);

    this.$el.trigger(this.options.changeEvent);
  }

  public canActivate(): boolean {
    return this._enabled && (this.options.excludeBreakpoints.indexOf(getCurrentBreakpoint()) === -1);
  }

  public get active(): boolean {
    return !!this._state;
  }

  public get activeTop(): boolean {
    return this._state === 'top';
  }

  public get activeLateChange(): boolean {
    return this._state === 'top' && (this.topLine + this.options.lateChangeTolerance < this.windowScrollTop);
  }

  public get activeBottom(): boolean {
    return this._state === 'bottom';
  }

  public get enabled(): boolean {
    return this._enabled;
  }

  public set enabled(state) {
    this._enabled = state;
    this.checkState();
  }

  public addStateListener(cb: AnyToVoidFnSignature): void {
    this.$el.on(`${this.options.changeEvent}.${this.options.ns}`, cb);
  }

  public removeStateListener(cb?: AnyToVoidFnSignature): void {
    this.$el.off(`${this.options.changeEvent}.${this.options.ns}`, cb);
  }

  // Events
  '{window} on:resize'(): void {
    this.updateInitialStateDebounced();
    this.checkStateDebounced();
  }

  '{window} on:breakpointchange'(): void {
    this.checkStateDebounced();
  }

  // Direct listening to improve responsiveness (RafDebounced)
  '{window} on:scroll.direct'(): void {
    this.checkStateDebounced();
  }
}

export default {
  name: 'StickyBox',
  initialize: (box: HTMLElement | JQuery<HTMLElement>, options?: ViewOptions): void => {
    box = $(box);
    if (box) {
      initComponent(StickyBox, box, options);
    }
  },
  getStickyBox: (element: HTMLElement | JQuery<HTMLElement>): StickyBox => {
    // @ts-ignore
    return View.getViewInstance(StickyBox, element, true);
  }
};

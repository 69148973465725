var MODULE_ALIAS = 'bundles/compilers/forms.compiler'; 
import {createCompiler} from 'core/core';
import {initComponent} from 'core/helpers/component';

export const compile = createCompiler('forms', ($) => {
  initComponent(require('hpe-forms/modal-form-init'));

 initComponent.$if($('hpe-form-item')) && require.ensure('hpe-forms/hpe-form-item',
            function(require) { initComponent.$if($('hpe-form-item')) && initComponent(require('hpe-forms/hpe-form-item'), $('hpe-form-item'));;},
            function(error) { require('core/log').error("Can't load hpe-forms/hpe-form-item", error); },
            'hpe-forms/hpe-form-item'
            );
 initComponent.$if($('hpe-form-wrapper')) && require.ensure('hpe-forms/hpe-form-wrapper',
            function(require) { initComponent.$if($('hpe-form-wrapper')) && initComponent(require('hpe-forms/hpe-form-wrapper'), $('hpe-form-wrapper'));;},
            function(error) { require('core/log').error("Can't load hpe-forms/hpe-form-wrapper", error); },
            'hpe-forms/hpe-form-wrapper'
            );
 initComponent.$if($('.basic-inpage-form')) && require.ensure('hpe-forms/basic-inpage-form-component',
            function(require) { initComponent.$if($('.basic-inpage-form')) && initComponent(require('hpe-forms/basic-inpage-form-component'), $('.basic-inpage-form'));;},
            function(error) { require('core/log').error("Can't load hpe-forms/basic-inpage-form-component", error); },
            'hpe-forms/basic-inpage-form-component'
            );
 initComponent.$if($('.gated-featured-resource-form')) && require.ensure('hpe-forms/featured-resource-form-component',
            function(require) { initComponent.$if($('.gated-featured-resource-form')) && initComponent(require('hpe-forms/featured-resource-form-component'), $('.gated-featured-resource-form'));;},
            function(error) { require('core/log').error("Can't load hpe-forms/featured-resource-form-component", error); },
            'hpe-forms/featured-resource-form-component'
            );
 initComponent.$if($('.embedded-appcloud-form')) && require.ensure('hpe-forms/embedded-form-component',
            function(require) { initComponent.$if($('.embedded-appcloud-form')) && initComponent(require('hpe-forms/embedded-form-component'), $('.embedded-appcloud-form'));;},
            function(error) { require('core/log').error("Can't load hpe-forms/embedded-form-component", error); },
            'hpe-forms/embedded-form-component'
            );
 initComponent.$if($('.standalone-form')) && require.ensure('hpe-forms/standalone-form-component',
            function(require) { initComponent.$if($('.standalone-form')) && initComponent(require('hpe-forms/standalone-form-component'), $('.standalone-form'));;},
            function(error) { require('core/log').error("Can't load hpe-forms/standalone-form-component", error); },
            'hpe-forms/standalone-form-component'
            );
 initComponent.$if($('.uc-inpage-form')) && require.ensure('hpe-forms/uc-inpage-form-component',
            function(require) { initComponent.$if($('.uc-inpage-form')) && initComponent(require('hpe-forms/uc-inpage-form-component'), $('.uc-inpage-form'));;},
            function(error) { require('core/log').error("Can't load hpe-forms/uc-inpage-form-component", error); },
            'hpe-forms/uc-inpage-form-component'
            );
});

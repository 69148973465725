var MODULE_ALIAS = 'bundles/compilers/gn.compiler'; 
import {createCompiler} from 'core/core';
import {initComponent} from 'core/helpers/component';

export const compile = createCompiler('gn', ($) => {
  initComponent.$if('#hf-footer-v3') && initComponent(require('hpe-gn/global-nav.footer.v3'), '#hf-footer-v3');

 initComponent.$if($('.anchor-nav-v3')) && require.ensure('hpe-gn/anchor-nav-v3',
            function(require) { initComponent.$if($('.anchor-nav-v3')) && initComponent(require('hpe-gn/anchor-nav-v3'), $('.anchor-nav-v3'));;},
            function(error) { require('core/log').error("Can't load hpe-gn/anchor-nav-v3", error); },
            'hpe-gn/anchor-nav-v3'
            );
 initComponent.$if($('country-selector')) && require.ensure('hpe-gn/country-selector',
            function(require) { initComponent.$if($('country-selector')) && initComponent(require('hpe-gn/country-selector'), $('country-selector'));;},
            function(error) { require('core/log').error("Can't load hpe-gn/country-selector", error); },
            'hpe-gn/country-selector'
            );
 initComponent.$if($('.js-gn-title-bar')) && require.ensure('hpe-gn/gn-title-bar',
            function(require) { initComponent.$if($('.js-gn-title-bar')) && initComponent(require('hpe-gn/gn-title-bar'), $('.js-gn-title-bar'));;},
            function(error) { require('core/log').error("Can't load hpe-gn/gn-title-bar", error); },
            'hpe-gn/gn-title-bar'
            );
 initComponent.$if($('uc-anchor-navigation')) && require.ensure('hpe-gn/anchor-navigation',
            function(require) { initComponent.$if($('uc-anchor-navigation')) && initComponent(require('hpe-gn/anchor-navigation'), $('uc-anchor-navigation'));;},
            function(error) { require('core/log').error("Can't load hpe-gn/anchor-navigation", error); },
            'hpe-gn/anchor-navigation'
            );
 initComponent.$if($('uc-breadcrumbs')) && require.ensure('hpe-gn/breadcrumbs',
            function(require) { initComponent.$if($('uc-breadcrumbs')) && initComponent(require('hpe-gn/breadcrumbs'), $('uc-breadcrumbs'));;},
            function(error) { require('core/log').error("Can't load hpe-gn/breadcrumbs", error); },
            'hpe-gn/breadcrumbs'
            );
});

import {listen} from '@exadel/esl/modules/esl-utils/decorators';
import {isVisible} from '@exadel/esl/modules/esl-utils/dom/visible';
import {ESLEventUtils, DelegatedEvent} from '@exadel/esl/modules/esl-event-listener/core';
import {ESLMediaQuery} from '@exadel/esl/modules/esl-media-query/core';
import {createDeferred, afterNextRender} from '@exadel/esl/modules/esl-utils/async';

import {debug} from 'core/log';
import {getAnchorNavHeight} from 'core/helpers/scroll';
import {getStickyHeaderHeight} from 'hpe-gn/hpe-gn.utils';
import {getStickyBarHeight} from 'collateral/sticky-bar.config';
import {parseAdaptiveValue} from 'core/helpers/breakpoints';

const findAllByID = (id: string): HTMLElement[] => {
  try {
    return Array.from(document.querySelectorAll(`#${id}`));
  } catch (e) {
    // In case of not perfectly valid id (e.g. just a number)
    return Array.from(document.querySelectorAll(`[id="${id}"]`));
  }
};

let instance: AnchorScroller;

/** System component to control anchor scrolling */
export class AnchorScroller {
  /** An additional timeout to correct scroll position (ms) */
  public static STABLE_TIME = 500; // ms
  public static SCROLL_TIME = 4000; // ms

  /** Trim passed hash string by leading '#' */
  public static cleanHash(hash: string): string {
    return hash.trim().replace(/^#?/, '');
  }

  /** Clean 'wcmmode=disabled' from the passed url */
  public static cleanUrl(url: string): URL {
    const newUrl = new URL(url.trim());
    newUrl.searchParams.delete('wcmmode');
    newUrl.hash = '';
    return newUrl;
  }

  /** @returns if hash presented */
  public static get hasHash(): boolean {
    return location.href.indexOf('#') !== -1;
  }

  /** @returns currently first visible active anchor element */
  public static get $anchor(): HTMLElement | null {
    const $els: HTMLElement[] = findAllByID(AnchorScroller.cleanHash(location.hash));
    if ($els.length === 0) return null;
    return $els.find((el) => isVisible(el, {})) || $els[0];
  }

  /** Last scroll Request Animation Frame ID */
  protected requestId: number;

  constructor() {
    if (instance) return instance;
    // Prevent initial scroll restoration when anchor presented
    window.history.scrollRestoration = AnchorScroller.hasHash ? 'manual' : 'auto';

    // Start scroll from the top of the page
    AnchorScroller.hasHash && setTimeout(() => {
      window.scrollTo(0, 0);
      afterNextRender(() => this.handleScroll());
    }, 10);

    // Subscribe listeners
    ESLEventUtils.subscribe(this);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    instance = this;
  }

  // Fix extra scrollTop on overflow:hidden elements
  protected fixScrollOffset(): void {
    let $el = AnchorScroller.$anchor?.parentElement;
    while ($el && $el !== document.body) {
      const {overflowY} = getComputedStyle($el);
      if (overflowY === 'hidden') $el.scrollTop = 0;
      $el = $el.parentElement;
    }
  }

  @listen({event: 'click', target: document.body, selector: 'a'})
  protected onHandleClick(e: DelegatedEvent<PointerEvent>): void {
    const $anchor = e.$delegate as HTMLAnchorElement;
    const openNewWindow = $anchor?.getAttribute('target') === '_blank';
    if (!$anchor || !$anchor.href || openNewWindow || e.defaultPrevented) return;

    const pageUrl = AnchorScroller.cleanUrl(location.href);
    const anchorUrl = AnchorScroller.cleanUrl($anchor.href);
    if (pageUrl.toString() !== anchorUrl.toString()) return;

    e.preventDefault();
    const {hash} = new URL($anchor.href.trim());
    const eventInit = {bubbles: false, detail: {hash}};
    ESLEventUtils.dispatch(window, 'hpe:anchor:hashchange', eventInit);
  }

  @listen({event: 'hashchange', target: window})
  protected onHashChange(e: HashChangeEvent): void {
    if (!AnchorScroller.hasHash) return;
    this.handleScroll();
    e.preventDefault();
  }

  @listen({event: 'hpe:anchor:hashchange', target: window})
  protected onRequestHashChange({detail}: CustomEvent): void {
    const hash = AnchorScroller.cleanHash(detail.hash ?? location.hash);
    history.pushState(null, null, `#${hash}`);
    if (!detail?.preventScroll) this.handleScroll();
  }

  @listen({event: 'hpe:anchor:reset', target: window})
  protected onScrollStart(): void {
    this.handleScroll();
  }

  @listen({event: 'hpe:scroll:to', target: window})
  protected onScrollTo(e: CustomEvent): void {
    e.detail?.$targetElement && this.scrollTo(e.detail?.$targetElement);
  }

  @listen({event: 'wheel', target: window})
  protected onWheel(): void {
    this.requestId && cancelAnimationFrame(this.requestId);
  }

  protected handleScroll(): void {
    if (!AnchorScroller.hasHash) return;

    const $anchor = AnchorScroller.$anchor;
    // Has no anchor element and hash presented
    if (!$anchor && location.hash) return;

    if ($anchor) {
      const detail = {initiator: 'anchor'};
      ESLEventUtils.dispatch($anchor, 'esl:show:request', {detail});
    }

    debug('Anchor Scroller: scroll to anchor ', $anchor);
    this.fixScrollOffset();
    this.scrollTo($anchor)
      .then(() => ESLEventUtils.dispatch(window, 'hpe:scroll:end', {bubbles: false}))
      .then(() => this.requestId = 0);
  }

  /** Check if anchor nav is sticky and above the passed element */
  protected isStickyAnchorNavAbove($el: HTMLElement | null): boolean {
    const $anHolder = document.querySelector('uc-anchor-holder');
    if ($anHolder) {
      if (ESLMediaQuery.for('@XS').matches && window.screenY > 0) return true;
      // eslint-disable-next-line no-bitwise
      return !!($el && $anHolder.compareDocumentPosition($el) & Node.DOCUMENT_POSITION_FOLLOWING);
    }
    // legacy anchor-nav-v3
    const $anchorNav = document.querySelector<HTMLElement>('.anchor-nav-v3.allow-sticky');
    const anchorNavTop = $anchorNav?.getBoundingClientRect().top;
    const elTop = $el?.getBoundingClientRect().top || 0;
    return $anchorNav && (ESLMediaQuery.for('@XS').matches || anchorNavTop < elTop);
  }

  protected calculateOffset($el: HTMLElement | null): number {
    const elementOffsetRule = $el?.getAttribute('data-anchor-offset');
    const elementOffset = elementOffsetRule && parseAdaptiveValue(elementOffsetRule).value;
    const elementOffsetValue = elementOffset === 'prev' ? ($el.previousElementSibling as HTMLElement)?.offsetHeight : +elementOffset;
    const stickyElHeight = this.isStickyAnchorNavAbove($el) ? getAnchorNavHeight() : getStickyHeaderHeight();
    // 0 if there aren't anchor or sticky header
    return Math.max(stickyElHeight - 2, getStickyBarHeight(), 0) + elementOffsetValue || 0;
  }

  /** Incremental scroll to passed element. Updates target position per each frame. */
  protected scrollTo($el: HTMLElement | null): Promise<void> {
    const deferred = createDeferred<void>();

    let startTime = Date.now();
    let lastUnstableTime = 0;
    const initialTime = startTime;
    let maxStepIncrement = 1;

    this.requestId && cancelAnimationFrame(this.requestId);
    const offset = this.calculateOffset($el);
    this.requestId = requestAnimationFrame(function step() {
      const distance = $el ? $el.getBoundingClientRect().top - offset : -window.scrollY;
      const distanceAbs = Math.abs(distance);
      // The speed of incremental scroll (px/ms) depends on the distance to the target position
      const speed = Math.floor(distanceAbs / 100) + 1;
      // The step increment limits by maxStepIncrement and provides a smooth start to scrolling
      const stepIncrement = Math.min(maxStepIncrement, speed * (Date.now() - startTime));
      startTime = Date.now();

      const increment = Math.floor(Math.min(distanceAbs, stepIncrement));
      const direction = distance > 0 ? increment : -increment;

      window.scrollTo(0, window.scrollY + direction);

      if (distanceAbs > 1) {
        lastUnstableTime = startTime;
      }
      if (maxStepIncrement < 1000) maxStepIncrement *= 2;
      if (startTime - lastUnstableTime < AnchorScroller.STABLE_TIME &&
        startTime - initialTime < AnchorScroller.SCROLL_TIME) {
        this.requestId = requestAnimationFrame(step.bind(this));
      } else {
        deferred.resolve();
      }
    }.bind(this));
    return deferred.promise;
  }
}

export default {
  initialize: () => new AnchorScroller()
};

import {memoize} from '@exadel/esl/modules/esl-utils/decorators';

import {Cache} from 'core/helpers/cache';
import PromiseUtils from 'core/helpers/promise-utils';

import type {VideoOverlayConfig} from 'core/video-overlay-types';

class VideoConfigLoader {
  @memoize()
  protected get cache(): Cache {
    return new Cache({
      'ns': 'video_service'
    });
  }

  public async load(url: string): Promise<VideoOverlayConfig> {
    const cached = this.cache.get(url);
    if (cached) return cached;

    const res = await fetch(url);
    const json = await PromiseUtils.fetchJson(res);
    this.cache.set(url, json);
    return json;
  }
}

export default new VideoConfigLoader();

/**
 * @return true if page is rendered in AEM Author 'EDIT' or 'DESIGN' mode
 * */
export function isAEMAuthorMode(): boolean {
  const wcmmode = document.body.dataset.wcmmode;
  return wcmmode === 'edit' || wcmmode === 'design';
}

/**
 * @return true if page is rendered in AEM Author 'PREVIEW' mode
 * */
export function isAEMPreviewMode(): boolean {
  const wcmmode = document.body.dataset.wcmmode;
  return wcmmode === 'preview';
}

/**
 * @return true if page is rendered in AEM Publish mode
 * */
export function isAEMPublishMode(): boolean {
  const wcmmode = document.body.dataset.wcmmode;
  return !wcmmode || wcmmode === 'disabled';
}

/**
 * @return true if the page is rendered in any of the AEM modes
 */
export function isAEM(): boolean {
  return !!document.body.dataset.wcmmode;
}

// Disable FF & Safary back-forward cache.
+window.addEventListener('unload', () => {
});

if (typeof window.onbeforeunload !== 'undefined') {
  window.onbeforeunload = function () {
  };
}

window.onpageshow = function (event) {
  if (event.persisted) {
    window.location.reload();
  }
};
// Amen

import {isObject} from '@exadel/esl/modules/esl-utils/misc/object';
export {isEqual, get, set, omit} from '@exadel/esl/modules/esl-utils/misc/object';

export function deepMerge<T>(obj: T): T;
export function deepMerge<T, U>(obj1: T, obj2: U): T | U;
export function deepMerge<T, U, V>(obj1: T, obj2: U, obj3: V): T | U | V;
export function deepMerge<T, U, V, W>(obj1: T, obj2: U, obj3: V, obj4: W): T | U | V | W;
/**
 * Performs a deep merge of objects and returns new object.
 * Does not modify objects (immutable)
 * @param objects to merge
 * @returns new object with merged key/values
 */
export function deepMerge(...objects: any[]): any {
  return objects.reduce((res: any, obj: any) => {
    isObject(obj) && Object.keys(obj).forEach((key) => {
      const resultVal = res[key];
      const objectVal = obj[key];

      if (Array.isArray(objectVal)) {
        res[key] = objectVal.slice();
      } else if (isObject(resultVal) && isObject(objectVal)) {
        res[key] = deepMerge(resultVal, objectVal);
      } else {
        res[key] = objectVal;
      }
    });

    return res;
  }, {});
}

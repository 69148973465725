import $ from 'jquery';
import {error} from 'core/log';
import {unwrap} from 'core/helpers/dom';
import {isMobileSafari} from 'core/helpers/browser';
import {getStickyHeaderHeight} from 'hpe-gn/hpe-gn.utils';

// TODO: Cleanup legacy jquery and use native scrollTo polyfill

/**
 * Used for disable touch scroll
 * It overrides document.ontouchmove because iOS Safari 9.3 ignore addEventListeners after touchstart action happened.
 *
 * @returns {Object} handler with enable and disable functions
 */
export function createDocumentTouchMoveHandler() {
  if (document.ontouchmove &&
    document.ontouchmove !== disableTouchMove &&
    document.ontouchmove !== enableTouchMove) {
    error('document.ontouchmove was defined but overwritten by DocumentTouchMoveHandler. Was:', document.ontouchmove);
  }
  if (isMobileSafari) {
    // iOS Safari 9.3 require initial update and reflow to correctly process first touch
    disableTouchMove();
    document.body.offsetHeight; // Reflow
    enableTouchMove();
  }
  return {
    enable: enableTouchMove,
    disable: disableTouchMove
  };
}

function disableTouchMove() {
  document.ontouchmove = (e) => {
    e.preventDefault();
  };
}

function enableTouchMove() {
  document.ontouchmove = () => {
    return true;
  };
}

let scrollbarWidth: number;

/**
 * Get browser scroll width
 * */
export function getScrollbarWidth() {
  if (scrollbarWidth === undefined) {
    let scrollbarParent = $('<div style="width:50px;height:50px;overflow:auto;"><div></div></div>').appendTo('body');
    let scrollbarChild = scrollbarParent.children();
    scrollbarWidth = scrollbarChild.innerWidth() - scrollbarChild.height(99).innerWidth();
    scrollbarParent.remove();
  }

  return scrollbarWidth;
}

/**
 * Check vertical scroll based on content height (css scroll property ignored)
 * @return {boolean}
 * */
export function hasVerticalScroll(el = document.documentElement) {
  el = unwrap(el);
  return el.scrollHeight > el.clientHeight;
}

type ScrollAppearance = 'none' | 'padding' | 'disabled' | 'enable';

/**
 * Change scroll appearance and disable/enable scroll on the page:
 * 'none' - to hide and disable scroll
 * 'padding' - to hide and disable scroll but compensate content jump via padding
 * 'disable' - to disable native scroll
 * 'enable' - to enable scroll
 * */
export function togglePageScroll(appearance: ScrollAppearance = 'enable') {
  const hasScroll = hasVerticalScroll();
  const scrollWidth = getScrollbarWidth();
  const docClassList = document.documentElement.classList;

  docClassList.toggle('disabled-scroll', appearance !== 'enable');
  docClassList.toggle('disabled-scroll-style', appearance !== 'enable' && (appearance !== 'disabled' || !hasScroll));

  if (appearance === 'padding') {
    $('body').css({'padding-right': (hasScroll ? `${scrollWidth}px` : '')});
  }
  if (appearance === 'enable') {
    $('body').css({'padding-right': ''});
  }
}

/**
 * Move page scroll to {@param top} position
 * @param {number} top
 * @param {boolean | number} [duration] - move impliedly if false
 * @param {function} [callback] - a function to call once the animation is complete
 * */
export function scrollTo(top: number, duration: number = 500, callback?: () => void) {
  if (typeof duration === 'number') {
    $('html, body').stop().animate({scrollTop: top}, duration, 'swing', () => {
      if (callback) callback();
    });
  } else {
    $('html, body').scrollTop(top);
  }
}

/**
 * Scrolls the element's parent container such that the $el is visible
 */
export function scrollToElement(el: HTMLElement | JQuery, options: ScrollIntoViewOptions = {}) {
  const $el = unwrap(el);
  $el && $el.scrollIntoView(Object.assign({behavior: 'smooth'}, options));
}

/**
 * Move page scroll to the top
 * @param {boolean | number} [duration] - move impliedly if false
 * */
export function scrollToTop(duration = 500) {
  setTimeout(() => {
    if ($(window).scrollTop() > 0) {
      scrollTo(0, duration);
    }
  }, 0);
}

export function getAnchorNavHeight(): number {
  const $anHolder = document.querySelector<HTMLElement>('uc-anchor-holder');
  if ($anHolder) return $anHolder.offsetHeight;
  const $anchorNav = document.querySelector<HTMLElement>('.anchor-nav-v3.allow-sticky');
  return $anchorNav ? $anchorNav.offsetHeight : 0;
}

export function getCollateralStickyBarHeight(): number {
  const $stickyBar = document.querySelector<HTMLElement>('hpe-sticky-bar');
  return $stickyBar ? $stickyBar.offsetHeight : 0;
}

/**
 * Move page scroll to the element
 * @params {HTMLElement | JQuery } [el] - element to move to
 * {number} [delay] - time of scrolling
 * */
export function scrollToAdjusted(el: HTMLElement | JQuery, offset: number = 2) {
  const $el = $(el);
  if (!$el.length) return;
  const top = $el.offset().top + offset - getAnchorNavHeight() - getStickyHeaderHeight();
  window.scrollTo({
    top: Math.max(top, 0),
    behavior: 'smooth'
  });
}

/** Promisified version of scrollIntoView */
export function scrollIntoViewPromisified(el: HTMLElement, options: ScrollIntoViewOptions = {}): Promise<void> {
  return new Promise((resolve) => {
    el.scrollIntoView(options);
    const intersectionObserver = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setTimeout(() => {
          resolve();
          intersectionObserver.unobserve(el);
        }, 100);
      }
    });
    intersectionObserver.observe(el);
  });
}

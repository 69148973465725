import {GenericModalService} from './generic-modal-service';
import {GenericModalLoader} from './generic-modal-loader';

export default {
  initialize: () => {
    GenericModalLoader.register();
    GenericModalService.init();
    GenericModalService.openFromUrl();
  }
};

import {unwrap} from 'core/helpers/dom';

export type ViewportStateChangeCallback = (entry: IntersectionObserverEntry) => void | boolean;

const callbackMap = new WeakMap<HTMLElement, Set<ViewportStateChangeCallback>>();
const observer = new IntersectionObserver(
  (entries) => entries.forEach(onStateChange),
  {threshold: 0}
);

function onStateChange(entry: IntersectionObserverEntry) {
  const el = entry.target as HTMLElement;
  const callbacks = callbackMap.get(el);
  if (!callbacks) return;
  callbacks.forEach((cb) => {
    if (cb(entry) === true) removeViewportListener(el, cb);
  });
}

export function addViewportListener(el: HTMLElement, cb: ViewportStateChangeCallback) {
  const callbacks = callbackMap.get(el) || new Set<ViewportStateChangeCallback>();
  if (!callbacks.size) observer.observe(el);
  callbacks.add(cb);
  callbackMap.set(el, callbacks);
}
export function removeViewportListener(el: HTMLElement, cb: ViewportStateChangeCallback) {
  const callbacks = callbackMap.get(el);
  if (!callbacks) return;
  callbacks.delete(cb);
  if (callbacks.size) return;
  callbackMap.delete(el);
  observer.unobserve(el);
}

export function onViewport($el: HTMLElement | JQuery, callback: ViewportStateChangeCallback) {
  const el = unwrap($el);
  addViewportListener(el, callback);
  return () => removeViewportListener(el, callback);
}

export function onViewportEnter($el: HTMLElement | JQuery, cb: ViewportStateChangeCallback) {
  return onViewport($el, (entry: IntersectionObserverEntry) => entry.isIntersecting ? cb(entry) : void 0);
}
export function onceViewportEnter($el: HTMLElement | JQuery, cb: ViewportStateChangeCallback) {
  return onViewport($el, (entry: IntersectionObserverEntry) => {
    if (!entry.isIntersecting) return false;
    cb(entry);
    return true;
  });
}

export function onViewportLeave($el: HTMLElement | JQuery, cb: ViewportStateChangeCallback) {
  return onViewport($el, (entry: IntersectionObserverEntry) => entry.isIntersecting ? void 0 : cb(entry));
}

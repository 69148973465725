// Global Typings & Global Namespace for ESL
import '@exadel/esl/modules/lib';

import {ESLScreenBreakpoints, ESLScreenDPR} from '@exadel/esl/modules/esl-media-query/core';

import BREAKPOINTS from './breakpoints.json';

// HPE Breakpoints registration
Object.keys(BREAKPOINTS).forEach((bp: keyof typeof BREAKPOINTS) => {
  const {min, max} = BREAKPOINTS[bp];
  ESLScreenBreakpoints.add(bp, min, max);
});

// Disable Upper DPRs for bots
ESLScreenDPR.ignoreBotsDpr = true;

import {sequentialUID} from '@exadel/esl/modules/esl-utils/misc/uid';
import {fontsLoaded} from 'core/helpers/fonts-loader';
import {onResize, onScroll, onWindowEvent} from 'core/helpers/window';
import {getCurrentBreakpoint, getPreviousBreakpoint} from 'core/helpers/breakpoints';

import type {View, EventCallback} from 'core/view';


export const windowHook = (event: string, callback: EventCallback, view: View): () => void => {
  switch (event) {
    case 'resize':
      return onResize(callback);
    case 'scroll':
      return onScroll(callback);
    case 'fonts.loaded':
      fontsLoaded.then(callback);
      return () => void 0;
    case 'breakpointchange': {
      const bpChangeCallback = () => callback(getCurrentBreakpoint(), getPreviousBreakpoint());
      setTimeout(bpChangeCallback, 1);
      return onWindowEvent(`hpe.breakpointchange.${view.cid}`, bpChangeCallback);
    }
    default:
      event += `.${view.cid}.${sequentialUID('we')}`;
      return onWindowEvent(event, callback);
  }
};

import {unwrap} from 'core/helpers/dom';

/** Supported Themes */
export type Theme = 'light' | 'dark';

/** Inverse passed theme */
export function reverseTheme(theme: Theme): Theme {
  switch (theme) {
    case 'light':
      return 'dark';
    case 'dark':
      return 'light';
    default:
      return theme;
  }
}

export type ThemeFormatter = (theme: string) => string;
export const DEFAULT_THEME_FORMATTER: ThemeFormatter = (theme: string) => `${theme}-theme`;

/** Setup element theme */
export function setTheme(el: JQuery | HTMLElement, theme: Theme | null, formatter: ThemeFormatter = DEFAULT_THEME_FORMATTER): void {
  const $el: HTMLElement = unwrap(el);
  if (!$el) return;
  if (theme) {
    $el.classList.add(formatter(theme));
    $el.classList.remove(formatter(reverseTheme(theme)));
  } else {
    $el.classList.remove(formatter('light'), formatter('dark'));
  }
}

export const setHeaderTheme =
  (theme: Theme): void => setTheme(document.querySelector('gn-header') as HTMLElement, theme);

export const getTheme = (el: JQuery | HTMLElement): Theme | null => {
  const $el: HTMLElement = unwrap(el);
  if ($el && $el.classList.contains('dark-theme')) return 'dark';
  if ($el && $el.classList.contains('light-theme')) return 'light';
  return null;
};

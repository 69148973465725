var MODULE_ALIAS = 'core/dependencies'; 
/**
 * Bundled dependencies list, to force webpack add them to the root bundle
 * Available through core public api (requireModule)
 * */
export * from 'js-cookie';
export * as JSX from 'jsx-dom';

import 'core/view';

import 'core/helpers/aem';
import 'core/helpers/auth-api';
import 'core/helpers/breakpoints';
import 'core/helpers/browser';
import 'core/helpers/cache';
import 'core/helpers/common-cookies';
import 'core/helpers/config';
import 'core/helpers/component';
import 'core/helpers/dom';
import 'core/helpers/date';
import 'core/helpers/dom-sizes'; // ??
import 'core/helpers/focus';
import 'core/helpers/keycodes';
import 'core/helpers/location';
import 'core/helpers/object';
import 'core/helpers/promise-utils';
import 'core/helpers/rss-loader';
import 'core/helpers/rte-checklist';
import 'core/helpers/rtl';
import 'core/helpers/scroll';
import 'core/helpers/url-suffixes';
import 'core/helpers/url-builder';
import 'core/helpers/url-search-params';
import 'core/helpers/viewport';
import 'core/helpers/window';

import 'cockpit/api/shared';

var MODULE_ALIAS = 'core/text-collapsible'; 
import 'dotdotdot';
import View from 'core/view';
import {dispatchNativeEvent, unwrap} from 'core/helpers/dom';
import {getCurrentBreakpoint} from 'core/helpers/breakpoints';
import {isLanguageCode} from 'core/helpers/config';

/**
 * Helper Component for collapsible text based on dotdotdot library
 *
 * rows - default rows count (-1 or NaN for disable)
 * [rowsxs] - rows count for XS breakpoint (-1 or NaN for disable)
 * [rowssm] - rows count for SM breakpoint (-1 or NaN for disable)
 * [rowsmd] - rows count for MD breakpoint (-1 or NaN for disable)
 * [rowslg] - rows count for LG breakpoint (-1 or NaN for disable)
 * [rowsxl] - rows count for XL breakpoint (-1 or NaN for disable)
 * */
class TextCollapsibleWrapper extends View { 
        static className = MODULE_ALIAS + '#TextCollapsibleWrapper';
         
      

  static dataAttrs = 'tc';
  static defaults = {
    text: '.text-collapsible',
    trigger: '.tc-trigger',
    enabledClass: 'tc-enabled',
    expandedClass: 'tc-expanded',
    tolerance: 0,
    postCorrection: false,
    letterTruncateLC: ['ja', 'zh']
  };

  init() {
    this.text = this.find(this.options.text);
    super.init();
    this.refresh();
  }

  get expanded() {
    return this.$el.hasClass(this.options.expandedClass);
  }
  set expanded(val) {
    this.$el.toggleClass(this.options.expandedClass, val);
    this.refresh();
    this.options.onStateChange && this.options.onStateChange.call(this, this.expanded);
  }

  /**
   * Toggle expanded state
   * */
  toggle() {
    this.expanded = !this.expanded;
  }

  get configMaxRows() {
    const rowsBP = this.options[`rows${getCurrentBreakpoint()}`];
    const maxRows = rowsBP || this.options.rows;
    return isNaN(maxRows) ? -1 : +maxRows;
  }

  calcMaxHeight() {
    const {text} = this;

    const lastTextTag = text.find('p,h2,h3,h4,h5');
    let lineHeight = lastTextTag.length ? parseFloat(lastTextTag.css('line-height')) : 0;
    lineHeight = lineHeight || parseFloat(text.css('line-height'));

    const height = lineHeight * this.maxRows;
    let extraspace = 0; // margins
    let parsHeight = 0; // height of all paragraphs without margins
    text.children('p,h2,h3,h4,h5').each((i, el) => {
      const {clientHeight} = el;
      const {marginTop, marginBottom} = window.getComputedStyle(el);
      parsHeight += clientHeight;

      if (parsHeight >= height) { // text will be cut on that paragraph
        extraspace += parseFloat(marginTop); // increase only top margin
        return false;
      }

      extraspace += parseFloat(marginTop) + parseFloat(marginBottom);
    });
    return Math.ceil(height + extraspace) + this.options.tolerance;
  }

  onDestroy() {
    this.text.trigger('destroy');
    this.text.css('max-height', 'none');
    this.removeClass(this.options.enabledClass);
    super.onDestroy();
  }

  /**
   * Update breakpoint config
   * */
  onBreakpointChange() {
    this.maxRows = this.configMaxRows;
    if (!this.text.length) return;
    if (this.maxRows > 0) {
      this.maxHeight = `${this.calcMaxHeight()}px`;
    } else {
      this.maxHeight = 'none';
    }
  }

  /**
   * Refresh according to current breakpoint config
   * */
  refresh() {
    if (!this.text.length) return;

    this.text.trigger('destroy');
    this.text.css('max-height', 'none');
    this.enabled = (this.maxHeight !== 'none') && this.text.innerHeight() > parseFloat(this.maxHeight);

    this.text.css('max-height', this.expanded ? 'none' : this.maxHeight);
    this.$el.toggleClass(this.options.enabledClass, this.enabled);

    this.recalculate();

    dispatchNativeEvent(this.$el, 'tc:refreshed');
  }

  /**
   * Recalculate dotdotdot if needed
   * */
  recalculate() {
    if (!this.enabled || this.expanded) return;
    this.text.dotdotdot({
      after: this.options.trigger,
      ellipsis: this.options.ellipsis,
      wrap: isLanguageCode(this.options.letterTruncateLC) ? 'letter' : 'word'
    });
    if (this.options.postCorrection) this.adjust();
  }

  adjust() {
    if (!this.enabled || this.expanded) return;

    const $last = this.text.children(':last-child');
    if (!$last.text().trim()) $last.remove();

    this.text.css('max-height', unwrap(this.text).scrollHeight);
  }

  // Events
  '{trigger} on:click'() {
    this.toggle();
  }

  '{window} on:breakpointchange'() {
    this.onBreakpointChange();
    this.refresh();
  }

  '{window} on:fonts.loaded'() {
    this.onBreakpointChange();
    this.refresh();
  }

  '{window} on:resize'() {
    this.refresh();
  }

  'on:tc:refresh'() {
    this.refresh();
  }
}

export default TextCollapsibleWrapper;

;exports.default.componentName = MODULE_ALIAS;
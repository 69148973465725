import {ESLEventUtils} from '@exadel/esl/modules/esl-utils/dom/events';
import {promisifyTimeout} from '@exadel/esl/modules/esl-utils/async';

import {error} from 'core/log';
import {scrollToElement} from 'core/helpers/scroll';
import {VideoUtils} from 'core/video-utils';
import VideoConfigLoader from 'core/video-config-loader';
import {VideoOverlay} from 'core/video-overlay';

import type {VideoOverlayConfig} from 'core/video-overlay-types';
import type {ESLMedia} from '@exadel/esl/modules/esl-media/core';

/**
 * Allows the user to open a video from the URL (Deep-link)
 *
 * Supported init strategies:
 *  - `overlay`  [default] Common video overlay initialization flow
 *
 *  - `embed`    Used to trigger an existing embedded smart-media element.
 *               Finds first video with provided resource, scrolls to the closest elem, marked as
 *               [data-video-share-anchor] or to elem itself.
 *               Algorithm:
 *
 *               1) parse mediaId(in case of the directUrlParam usage or corresponding overlay trigger availability)
 *               2) [if not] request video metadata, extract mediaId
 *               3) check an appropriate smart-media[media-id=${id}] element existence and trigger 'shared:play' event on it
 *               4) [if not] initialize overlay flow
 *
 *  - 'delegate' Used to delegate video handling to the components' layer.
 *               Delegated video trigger must be marked as:
 *               [data-delegated-video-trigger][data-media-resource=${resource}] or [data-delegated-video-trigger][data-media-id=${id}]. Algorithm:
 *
 *               1) the same as 'embed'
 *               2) the same as 'embed'
 *               3) check a marked delegated element existence
 *               4) the same as 'embed'
 */
export class VideoInitializer {
  protected static scrollDelay = 1000;

  public static async init(config: VideoOverlayConfig = VideoUtils.getMediaConfig()): Promise<Event | void> {
    if (!config) return;

    switch (config.strategy) {
      case VideoUtils.mediaStrategies.embed:
        return this.initEmbeddedFlow(config);
      case VideoUtils.mediaStrategies.delegate:
        return this.initDelegatedFlow(config);
      case VideoUtils.mediaStrategies.overlay:
      default:
        return this.initOverlayFlow(config);
    }
  }

  /**
   * Uses existing trigger to provide video title and share link.
   * If video trigger wasn't found on the page it opens video overlay w/o title and sharelink
   */
  protected static async initOverlayFlow(config: VideoOverlayConfig): Promise<Event | void> {
    let selector = `[hpe-video-overlay="${this.getVideoOverlayAttr(config)}"]`;
    if (config.startTime) selector += `[data-attribute-start-time="${config.startTime}"]`;
    const $link = document.querySelector(selector) as HTMLAnchorElement;

    /**
     * If trigger exists on the page, than we use it as action initiator to receive additional attributes
     * and delegate focus on close.
     */
    if ($link) {
      $link.click();
    } else {
      return VideoOverlay.open(config);
    }
  }

  /**
   * When there are 2 or more embedded video instances on the page - the first one must be showed
   * When video is no longer exists on the page or there is no such embedded video on a page - video overlay must be showed
   */
  protected static async initEmbeddedFlow(config: VideoOverlayConfig): Promise<Event | void> {
    const id = await this.getMediaId(config);
    const $medias = [...document.querySelectorAll('smart-media')] as ESLMedia[];
    const $media = $medias.find((media) => media.mediaId === id || media.mediaSrc === id);
    if (!$media) return this.initOverlayFlow(config);

    await promisifyTimeout(this.scrollDelay);
    const $anchor = ($media.closest('[data-video-share-anchor]') || $media) as HTMLElement;
    scrollToElement($anchor, {block: 'center'});
    ESLEventUtils.dispatch($media, 'shared:play');
    VideoUtils.clearUrl();
  }

  /** When there is no potential delegated trigger for the id  - video overlay must be showed */
  protected static async initDelegatedFlow(config: VideoOverlayConfig): Promise<void> {
    if (config.resource) {
      const $trigger = document.querySelector(`[data-delegated-video-trigger][data-media-resource="${config.resource}"]`);
      if ($trigger) return;
    }
    const id = await this.getMediaId(config);
    const $trigger = document.querySelector(`[data-delegated-video-trigger][data-media-id=${id}]`);
    if (!$trigger) await this.initOverlayFlow(config);
  }

  /** Parse/request mediaId using the given config */
  protected static async getMediaId(config: VideoOverlayConfig): Promise<string> {
    if (config.id) return config.id;
    // Small extra request is fine to get the mediaId correct without additional routine
    if (config.resource) return (await VideoConfigLoader.load(config.resource)).id;
    return '';
  }

  protected static getVideoOverlayAttr(config: VideoOverlayConfig): string {
    const {id, resource, type} = config;
    return (resource) ? resource : `${type}:${id}`;
  }
}

let isInitializerActivated: boolean;

export const activateVideoInitializer = async (): Promise<Event | void> => {
  if (isInitializerActivated) return;
  isInitializerActivated = true;

  VideoOverlay.register();
  await customElements.whenDefined('smart-media');
  return VideoInitializer.init();
};

export const initialize = () => activateVideoInitializer().catch(error);

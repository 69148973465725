import {unwrap} from 'core/helpers/dom';
import {initComponent} from 'core/helpers/component';
import {isAEMAuthorMode} from 'core/helpers/aem';
import {setHeaderTheme} from 'core/helpers/themes';

export const BGW_PARALLAX_CLASS = 'bg-wrapper-parallax';
export const BGW_UNDERLAP_CLASS = 'bg-wrapper-underlap';

export default {
  name: 'bg-wrapper',
  initialize(el: JQuery<HTMLElement>) {
    if (isAEMAuthorMode()) return;
    const {classList} = unwrap(el);

    if (classList.contains(BGW_UNDERLAP_CLASS)) {
      setHeaderTheme(classList.contains('dark-theme') ? 'dark' : 'light');
    }

    if (classList.contains(BGW_PARALLAX_CLASS)) {
      import(/*webpackChunkName: "design-3.0/background-wrapper-parallax"*/'design-3.0/background-wrapper-parallax')
        .then((component: any) => initComponent(component, el));
    }
  }
};

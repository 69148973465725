import {debug} from 'core/log';
import {memoize} from '@exadel/esl/modules/esl-utils/decorators/memoize';

import type {CockpitApi} from 'cockpit/api/cockpit-api';
import type {AuthAPI} from 'cockpit/api/auth/auth-api';
import type {KnowMeApi} from 'cockpit/api/know-me/know-me-api';
import type {AccountAPI} from 'cockpit/api/account/account-api';

export type {CockpitApi, AuthAPI, KnowMeApi, AccountAPI};

/** Shared promisified {@link CockpitApi} to use inside outer modules */
export class SharedAPI {
  /** Unsafe Sync cockpit api instance*/
  public static cockpit: CockpitApi;

  /** Safe cached {@link CockpitApi} accessor */
  @memoize()
  public static get cockpit$(): Promise<CockpitApi> {
    const $promise = import(/* webpackChunkName: 'hpe.cockpit-api.js' */'cockpit/api/cockpit-api')
      .then((data) => (this.cockpit = data.CockpitApi.getInstance()));
    $promise.catch((err) => debug('Cannot load Cockpit API chunk', err));
    return $promise;
  }

  /** Safe shortcut for {@link CockpitApi.auth} accessor */
  public static get auth$(): Promise<AuthAPI> {
    return this.cockpit$.then((api: CockpitApi) => api.auth);
  }

  /** Safe shortcut for {@link CockpitApi.knowMe} accessor */
  public static get knowMe$(): Promise<KnowMeApi> {
    return this.cockpit$.then((api: CockpitApi) => api.knowMe);
  }

  /** Safe shortcut for {@link CockpitApi.account} accessor */
  public static get account$(): Promise<AccountAPI> {
    return this.cockpit$.then((api: CockpitApi) => api.account);
  }
}

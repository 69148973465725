type URLParams = Record<string, string | number | boolean | string[]>;

const isURLParams = (value: URLParams | string): value is URLParams => {
  return (value && !Array.isArray(value) && typeof value === 'object');
};
const isString = (value: any): value is string => {
  return (value && typeof value === 'string');
};
/**
 * Include some functions in the default URLSearchParams interface
 */
declare global {
  interface URLSearchParams {
    /**
     * Append all params of the specified object
     * @param {Object} appendValue
     * @param {Boolean} ignoreEmptyParams
     * @returns {URLSearchParams}
     */
    appendAll(appendValue: any, ignoreEmptyParams?: boolean): URLSearchParams;

    /**
     * Set all params of the specified object
     * @param {Object} setValue
     * @param {Boolean} ignoreEmptyParams
     * @returns {URLSearchParams}
     */
    setAll(setValue: any, ignoreEmptyParams: boolean): URLSearchParams;

    /**
     * Delete all empty params
     */
    deleteEmpty(): void;

    /**
     * Returns an object with each property name and value corresponding to the entries of class
     * @returns {Object}
     */
    toObject(): any;
  }
}

// Functions implementation

URLSearchParams.prototype.appendAll = function (appendValue: URLParams, ignoreEmptyParams: boolean = false): URLSearchParams {
  _all(this.append.bind(this), appendValue, ignoreEmptyParams);
  return this;
};

URLSearchParams.prototype.setAll = function (setValue: URLParams, ignoreEmptyParams: boolean = false): URLSearchParams {
  _all(this.set.bind(this), setValue);
  if (ignoreEmptyParams) {
    this.deleteEmpty();
  }
  return this;
};

URLSearchParams.prototype.deleteEmpty = function (): void {
  Array.from(this.keys())
    .filter((key: string) => !this.getAll(key).some((val: string) => val !== '')) // filter empty
    .forEach((key: string) => this.delete(key)); // delete empty
};

URLSearchParams.prototype.toObject = function (): Record<string, string | string[]> {
  const obj: Record<string, string | string[]> = {};
  for (const key of this.keys()) {
    obj[key] = this.getAll(key);
  }
  for (const [param, value] of Object.entries(obj)) {
    if (value.length === 1) {
      obj[param] = value[0];
    }
  }
  return obj;
};

export const URLSearchParamsUtils = {
  /**
   * Create new URLSearchParams object from url string or object (like appendAll)
   * @param {string|Object} fromValue
   * @returns {URLSearchParams}
   */
  from: (fromValue: (URLParams | string) = ''): URLSearchParams => {
    if (isURLParams(fromValue)) {
      const usp: URLSearchParams = new URLSearchParams();
      usp.appendAll(fromValue);
      return usp;
    }
    if (isString(fromValue)) {
      return _isQueryString(fromValue) ? (new URLSearchParams(fromValue)) : (new URL(fromValue).searchParams);
    }
    return new URLSearchParams();
  }
};

// Private functions

function _isQueryString(str: string): boolean {
  switch (str.indexOf('?')) {
    case -1:
      return (str.indexOf('=') > 0); // query string without leading '?' has '=' symbol, otherwise, it is URL
    case 0:
      return true; // query string with leading '?'
    default:
      return false; // its URL
  }
}

function _all(cb: (name: string, value: string) => void, appendValue: URLParams, ignoreEmptyParams: boolean = false) {
  if (isURLParams(appendValue)) {
    for (const key of Object.keys(appendValue)) {
      if (!(ignoreEmptyParams && appendValue[key] === '')) {
        if (appendValue[key] && Array.isArray(appendValue[key])) {
          (appendValue[key] as string[]).forEach((val: string) => cb(key, val));
        } else {
          cb(key, String(appendValue[key]));
        }
      }
    }
  } else if (isString(appendValue)) {
    const sp = new URLSearchParams(appendValue);
    for (const newEntry of sp.entries()) {
      cb(newEntry[0], newEntry[1]);
    }
  }
}

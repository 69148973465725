import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';
import {attr, listen} from '@exadel/esl/modules/esl-utils/decorators';
import {ModalForm} from './modal-form';

class ModalFormTrigger extends ESLMixinElement {
  static is = 'hpe-modal-form';

  @attr({name: ModalFormTrigger.is}) formType: string;
  @attr({dataAttr: true}) public formPath: string;

  protected override connectedCallback(): void {
    super.connectedCallback();
    // A11y shims
    this.$$attr('role', 'button');
    if (this.$host.tagName === 'A') this.$$attr('href', 'javascript: void 0');
  }

  @listen('click')
  protected onClick(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    ModalForm.open({
      form: {
        type: this.formType,
        path: this.formPath
      },
      activator: this.$host
    });
  }
}

export default ModalFormTrigger;

import {ESLBaseElement, ESLBaseElementShape} from '@exadel/esl/modules/esl-base-element/core';
import {attr, bind} from '@exadel/esl/modules/esl-utils/decorators';
import {ESLImage} from '@exadel/esl/modules/esl-image/core';

export class HPEAvatar extends ESLBaseElement {
  public static is = 'hpe-avatar';

  @attr() public username: string;
  @attr() public imageUrl: string;

  static get observedAttributes() {
    return ['username', 'image-url'];
  }

  set image(val: string) {
    this.innerHTML = '';
    const $img = document.createElement('smart-image') as ESLImage;
    $img.src = val;
    $img.mode = 'cover';
    $img.containerClass = 'with-image';
    $img.containerClassState = 'loaded';
    this.appendChild($img);
    $img.$$on('error', this._onImageError);
  }

  set text(val: string) {
    this.innerHTML = '';
    const $text = document.createElement('div');
    $text.classList.add('hpe-avatar-text');
    $text.textContent = val;
    this.appendChild($text);
  }

  protected connectedCallback() {
    super.connectedCallback();
    this.update();
  }

  protected attributeChangedCallback(attrName: string, oldVal: string, newVal: string) {
    if (!this.connected || oldVal === newVal) return;
    this.update();
  }

  protected updateText(): void {
    const username = this.username || '';
    this.text = username.trim()
      .split(' ')
      .reduce((acc, el, index) => (index > 1) ? acc : acc + el.slice(0, 1), '');
  }

  protected update(): void {
    if (this.imageUrl) {
      this.image = this.imageUrl;
    } else {
      this.updateText();
    }
  }

  @bind
  protected _onImageError(): void {
    this.updateText();
  }
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'hpe-avatar': ESLBaseElementShape<HPEAvatar>;
    }
  }
}

declare global {
  export interface HTMLElementTagNameMap {
    'hpe-avatar': HPEAvatar;
  }
}

import {ESLPopup, ESLPopupActionParams} from '@exadel/esl/modules/esl-popup/core';
import {listen} from '@exadel/esl/modules/esl-utils/decorators/listen';
import {memoize} from '@exadel/esl/modules/esl-utils/decorators/memoize';
import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';

export class PrivacyTooltip extends ESLPopup {
  static is = 'privacy-tooltip';
  static LINK_TARGET = 'a[data-external]';

  connectedCallback(): void {
    super.connectedCallback();
    this.classList.add(ESLPopup.is);
  }

  @memoize()
  get $link(): HTMLAnchorElement {
    return this.querySelector('.privacy-tooltip-cta');
  }

  public onShow(params: ESLPopupActionParams): void {
    super.onShow(params);
    if (!(this.activator instanceof HTMLAnchorElement)) return;
    this.$link.href = this.activator.dataset.href;
    this.$link.target = this.activator.target;
  }

  public onHide(params: ESLPopupActionParams): void {
    super.onHide(params);
    this.$link.href = 'javascript:void(0)';
    this.$$off(this.onMouseOut);
  }

  @listen({
    event: 'click mouseover',
    selector: PrivacyTooltip.LINK_TARGET,
    target: document.body
  })
  protected onActivate(e: PointerEvent): void {
    const isHover = e.type === 'mouseover';
    const $target = e.target as HTMLElement;
    const $link = $target.closest(PrivacyTooltip.LINK_TARGET) as HTMLAnchorElement;
    if (this.open && !this._trackHover && isHover) return;

    this.show({
      activator: $link,
      force: this.activator !== $link,
      showDelay: this.open ? 200 : 500
    });
    this.bindHoverStateTracking(isHover);
    isHover && this.$$on({event: 'mouseout', target: $link}, this.onMouseOut);
    e.preventDefault();
    e.stopImmediatePropagation();
  }

  @listen({
    event: 'mouseout',
    selector: PrivacyTooltip.LINK_TARGET,
    auto: false,
    once: true
  })
  protected onMouseOut(): void {
    if (!this._trackHover) return;
    this.hide({
      hideDelay: 200,
      trackHover: true
    });
  }

  @listen({
    event: 'mouseup',
    selector: PrivacyTooltip.LINK_TARGET,
    target: document.body
  })
  protected onMouseup(e: PointerEvent): void {
    if (e.button === 1) this.onActivate(e);
  }
}

export class ExternalLinkMixin extends ESLMixinElement {
  static is = 'data-external';
  public readonly $host: HTMLAnchorElement;

  protected override connectedCallback() {
    this.$host.dataset.href = this.$host.href;
    this.$host.href = 'javascript: void(0)';
  }
}

export default {
  initialize: () => {
    const isBot = /Googlebot/i.test(navigator.userAgent);
    if (isBot) return;
    ExternalLinkMixin.register();
    PrivacyTooltip.register();
  }
};

import $ from 'jquery';
import Core from 'core/core';
import {unwrap} from 'core/helpers/dom';
import {$window} from 'core/helpers/window';

import type {View} from 'core/view';

export const GC_COMPLETED_EVENT = 'gc-completed';
export const MARKER = 'core-view';
const registry = new WeakMap<HTMLElement, View[]>();

export type IViewRegistry = typeof ViewRegistry;

export class ViewRegistry {
  static get registry(): WeakMap<HTMLElement, View[]> {
    return registry;
  }

  /** Register View instance */
  static register(view: View): void {
    const el = unwrap(view.$el);
    if (!el) return;
    el.setAttribute(MARKER, '');
    const views = registry.get(el) || [];
    if (views.indexOf(view) === -1) {
      registry.set(el, views.concat(view));
    }
  }

  /** Get instance of View registered for the element */
  static getInstance<U extends typeof View>(Class: U, element: HTMLElement | JQuery<HTMLElement>, findInParent = false): InstanceType<U> | null {
    const el = unwrap(element);
    if (!el) return null;

    const views = registry.get(el) || [];
    for (let i = views.length; i >= 0; i--) {
      if (views[i] instanceof Class) {
        return views[i] as InstanceType<U>;
      }
    }

    if (findInParent) {
      let view = null, current = el.parentElement;
      while (current && !view) {
        view = ViewRegistry.getInstance(Class, current);
        current = current.parentElement;
      }
      return view;
    }
    return null;
  }

  /** Return all known instances for element */
  static getAllInstances(element: HTMLElement | JQuery<HTMLElement>, deep: boolean): View[] {
    const el = unwrap(element);
    if (!el) return [];
    if (deep) {
      const viewEls = Array.from(el.querySelectorAll(`[${MARKER}]`) || []) as HTMLElement[];

      if (el.hasAttribute(MARKER)) {
        viewEls.push(el);
      }

      return viewEls
        .map((viewEl) => registry.get(viewEl))
        .reduce((arr, views) => arr.concat(views || []), []);
    }
    return registry.get(el) || [];
  }

  /**
   * Destroy all known instances for element.
   * NOTE: unsafe: will not check scope element and will not remove element itself
   * */
  static destroyKnownViews(scope: HTMLElement | JQuery<HTMLElement>, exclude: View[] = []): void {
    ViewRegistry.getAllInstances(scope, true).forEach((view) => {
      view && (exclude.indexOf(view) === -1) && view.destroy();
    });
  }

  /**
   * Garbage collection live-cycle acton definition
   * Destroy views and remove element itself.
   * Note: scope should not be part of active document
   * */
  static executeGC(scope: HTMLElement | JQuery<HTMLElement>): void {
    scope = unwrap(scope);
    if (scope && !document.body.contains(scope)) {
      // Destroy all related views
      ViewRegistry.destroyKnownViews(scope);
      // Initiate JQuery remove after delay to postpone it after view removal
      setTimeout(() => {
        $(scope).remove();
        $window.trigger(GC_COMPLETED_EVENT);
      }, 500);
    }
  }
}

Core.$registry = ViewRegistry;

export default ViewRegistry;

import {get, set} from '@exadel/esl/modules/esl-utils/misc/object';

export const DEFAULT_EVENT_PREFIX: string = 'ANALYTICS';
export const DEFAULT_EVENT_NAME: string = 'HVA';

/** dispatches HVA analytics events */
export function fireAnalyticsEvent(data: Record<string, any>, eventName?: string): void {
  eventName = eventName || `${DEFAULT_EVENT_PREFIX}.${DEFAULT_EVENT_NAME}`;
  window.dispatchEvent(new CustomEvent(eventName, {detail: data}));
}

export const getDigitalData = (path: string, defaultValue?: any) => get(window.digitalData, path, defaultValue);
export const setDigitalData = (path: string, value: any) => window.digitalData && set(window.digitalData, path, value);

/** Returns parentPage from analytics pageInfo object */
export function getParentPageParam(): string {
  return getDigitalData('page.pageInfo.breadCrumbs', []).slice(5).join(':') || '';
}

/** Returns parentPage from referrer if referrer current origin */
export function getParentPageFromReferrer(): string[] {
  return document.referrer && isReferrerMatch() ?
    (new URL(document.referrer).pathname.replace('.html', '')).split('/').slice(3) :
    [];
}

/** Returns true if document.referrer match our site */
export function isReferrerMatch() {
  return (new URL(document.referrer)).origin === window.location.origin;
}

declare global {
  interface Window {
    digitalData: any;
    trackMetrics(name: string, data: Record<string, any>): void;
  }
}

var MODULE_ALIAS = 'core/animate'; 
import View from 'core/view';
import ViewRegistry from 'core/view.registry';
import {debounce} from '@exadel/esl/modules/esl-utils/async/debounce';
import {parseDataAttrs, afterNextRender, dispatchNativeEvent} from 'core/helpers/dom';
import {$window} from 'core/helpers/window';

const GROUP_SELECTOR = '[data-animate-group]';

const IOOptions = {
  threshold: [0.001]
};

const delay = (fn, time) => time > 0 ? setTimeout(() => fn(), time) : fn();

class AnimateService { 
        static className = MODULE_ALIAS + '#AnimateService';
         
      
  constructor() {
    this.markedElements = [];
    this.io = new IntersectionObserver(this.onIntersect.bind(this), IOOptions);
  }

  onIntersect(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.markedElements.push(entry.target);
        this.postponedAnimate();
        observer.unobserve(entry.target);
      }
    });
  }

  initAnimate() {
    this.markedElements.forEach((el, index) => {
      const view = View.getViewInstance(AnimateItem, el);
      if (view && typeof view.doAnimate === 'function') {
        view.doAnimate(index);
      }
    });
    this.markedElements = [];
  }

  postponedAnimate = debounce(() => this.initAnimate(), 100);

  subscribe(el) {
    this.io.observe(el);
  }

  unsubscribe(el) {
    this.io.unobserve(el);
  }
}

let animateInstance;

/**
 * Animate Item - core view to provide appearing animation
 * @author Julia Murashko
 *
 * @attr:
 *  {String} data-animate - class to initiate animation
 *  {String} [data-animate-delay] - animation delay (ms) (default '200')
 *  {String} [data-animate-group-delay] - delay for each item in group (default '0')
 *  {String} [data-animate-init-delay] - delay before subscription (default '0')
 *  {boolean} [data-animate-ignore-scroll-top] - initiate animation immediately for elements above current position
 *  {String} [data-animate-group] - attribute-marker to identify closest container
 *
 *  @example
 *  <div class="fade" data-animate="in"></div>
 * */
class AnimateItem extends View { 
        static className = MODULE_ALIAS + '#AnimateItem';
         
      
  static defaults = {
    defaultClass: 'animated',
    initDelay: 0,
    delay: 200,
    groupDelay: 0,
    ignoreScrollTop: false
  };
  static dataAttrs = 'animate';

  static get serviceInstance() {
    if (!animateInstance) {
      animateInstance = new AnimateService();
    }
    return animateInstance;
  }

  /**
   * Initiate existing AnimateItem "re-animate"
   * @param {HTMLElement | JQuery} element - container to find AnimateItems
   */
  static reanimate(element) {
    ViewRegistry.getAllInstances(element, true)
      .filter((el) => el instanceof AnimateItem)
      .forEach((item) => item.reanimate());
  }

  get targetClass() {
    return this.$el.attr('data-animate') || this.options.defaultClass;
  }

  init() {
    if (this.$el.hasClass(this.targetClass)) {
      return;
    }
    this.attachListener();
  }

  reanimate() {
    this.removeClass(this.targetClass);
    afterNextRender(() => this.attachListener());
  }

  attachListener() {
    delay(() => {
      if (this.options.ignoreScrollTop && this.isBellow()) {
        this.doAnimate(0);
      } else {
        this.constructor.serviceInstance.subscribe(this.$el.get(0));
      }
    }, this.options.initDelay);
  }

  isBellow() {
    if (this.$el.is(':hidden')) {
      return false;
    }
    return this.$el.offset().top < $window.height() + $window.scrollTop();
  }

  onDestroy() {
    this.constructor.serviceInstance.unsubscribe(this.$el.get(0));
    super.onDestroy();
  }

  doAnimate(index) {
    delay(() => {
      this.addClass(this.targetClass);
      dispatchNativeEvent(this.$el, 'hpe:animated');
    }, this.options.delay + (index || 0) * this.options.groupDelay);
  }

  _initOptions(options = {}) {
    const $container = this.$el.closest(GROUP_SELECTOR);
    const containerOptions = $container.length ?
      parseDataAttrs(GROUP_SELECTOR, AnimateItem.dataAttrs) : {};
    return super._initOptions.call(this, Object.assign({}, containerOptions, options));
  }
}

export default AnimateItem;

;exports.default.componentName = MODULE_ALIAS;
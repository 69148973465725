var MODULE_ALIAS = 'bundles/compilers/browsing-page.compiler'; 
import {createCompiler} from 'core/core';
import {initComponent} from 'core/helpers/component';

export const compile = createCompiler('browsing-page', ($) => {
 initComponent.$if($('.newsroom-timeline')) && require.ensure('hpe-browsing-page/timeline',
            function(require) { initComponent.$if($('.newsroom-timeline')) && initComponent(require('hpe-browsing-page/timeline'), $('.newsroom-timeline'));;},
            function(error) { require('core/log').error("Can't load hpe-browsing-page/timeline", error); },
            'hpe-browsing-page/timeline'
            );
 initComponent.$if($('.js-bookmark-provider')) && require.ensure('hpe-browsing-page/bookmark-provider',
            function(require) { initComponent.$if($('.js-bookmark-provider')) && initComponent(require('hpe-browsing-page/bookmark-provider'), $('.js-bookmark-provider'));;},
            function(error) { require('core/log').error("Can't load hpe-browsing-page/bookmark-provider", error); },
            'hpe-browsing-page/bookmark-provider'
            );

 initComponent.$if($('.js-content-browser-v2')) && require.ensure('hpe-browsing-page/content-browser-v2-init-strategy',
            function(require) { initComponent.$if($('.js-content-browser-v2')) && initComponent(require('hpe-browsing-page/content-browser-v2-init-strategy'), $('.js-content-browser-v2'));;},
            function(error) { require('core/log').error("Can't load hpe-browsing-page/content-browser-v2-init-strategy", error); },
            'hpe-browsing-page/content-browser-v2-init-strategy'
            );
 initComponent.$if($('.js-resource-library')) && require.ensure('hpe-browsing-page/resource-library-init-strategy',
            function(require) { initComponent.$if($('.js-resource-library')) && initComponent(require('hpe-browsing-page/resource-library-init-strategy'), $('.js-resource-library'));;},
            function(error) { require('core/log').error("Can't load hpe-browsing-page/resource-library-init-strategy", error); },
            'hpe-browsing-page/resource-library-init-strategy'
            );
 initComponent.$if($('.js-elastic-browser')) && require.ensure('hpe-browsing-page/elastic-browser-init-strategy',
            function(require) { initComponent.$if($('.js-elastic-browser')) && initComponent(require('hpe-browsing-page/elastic-browser-init-strategy'), $('.js-elastic-browser'));;},
            function(error) { require('core/log').error("Can't load hpe-browsing-page/elastic-browser-init-strategy", error); },
            'hpe-browsing-page/elastic-browser-init-strategy'
            );
 initComponent.$if($('.js-bookmarks-dashboard')) && require.ensure('hpe-browsing-page/bookmarks-dashboard-init-strategy',
            function(require) { initComponent.$if($('.js-bookmarks-dashboard')) && initComponent(require('hpe-browsing-page/bookmarks-dashboard-init-strategy'), $('.js-bookmarks-dashboard'));;},
            function(error) { require('core/log').error("Can't load hpe-browsing-page/bookmarks-dashboard-init-strategy", error); },
            'hpe-browsing-page/bookmarks-dashboard-init-strategy'
            );
});


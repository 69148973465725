var MODULE_ALIAS = 'bundles/compilers/my-account.compiler'; 
import {createCompiler} from 'core/core';
import {initComponent} from 'core/helpers/component';

export const compile = createCompiler('my-account', ($) => {
 initComponent.$if($('.welcome-account')) && require.ensure('hpe-my-account/welcome-account',
            function(require) { initComponent.$if($('.welcome-account')) && initComponent(require('hpe-my-account/welcome-account'), $('.welcome-account'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/welcome-account", error); },
            'hpe-my-account/welcome-account'
            );
 initComponent.$if($('.service-links')) && require.ensure('hpe-my-account/service-links',
            function(require) { initComponent.$if($('.service-links')) && initComponent(require('hpe-my-account/service-links'), $('.service-links'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/service-links", error); },
            'hpe-my-account/service-links'
            );
 initComponent.$if($('.orders-table')) && require.ensure('hpe-my-account/orders-table',
            function(require) { initComponent.$if($('.orders-table')) && initComponent(require('hpe-my-account/orders-table'), $('.orders-table'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/orders-table", error); },
            'hpe-my-account/orders-table'
            );
 initComponent.$if($('.my-tasks-section')) && require.ensure('hpe-my-account/my-tasks-section',
            function(require) { initComponent.$if($('.my-tasks-section')) && initComponent(require('hpe-my-account/my-tasks-section'), $('.my-tasks-section'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/my-tasks-section", error); },
            'hpe-my-account/my-tasks-section'
            );
 initComponent.$if($('.stat-task')) && require.ensure('hpe-my-account/static-task',
            function(require) { initComponent.$if($('.stat-task')) && initComponent(require('hpe-my-account/static-task'), $('.stat-task'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/static-task", error); },
            'hpe-my-account/static-task'
            );
 initComponent.$if($('.expiration-reminder')) && require.ensure('hpe-my-account/expiration-reminder',
            function(require) { initComponent.$if($('.expiration-reminder')) && initComponent(require('hpe-my-account/expiration-reminder'), $('.expiration-reminder'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/expiration-reminder", error); },
            'hpe-my-account/expiration-reminder'
            );
 initComponent.$if($('.subscriptions-reminder')) && require.ensure('hpe-my-account/subscriptions-reminder',
            function(require) { initComponent.$if($('.subscriptions-reminder')) && initComponent(require('hpe-my-account/subscriptions-reminder'), $('.subscriptions-reminder'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/subscriptions-reminder", error); },
            'hpe-my-account/subscriptions-reminder'
            );
 initComponent.$if($('.software-invitation-v3')) && require.ensure('hpe-my-account/software-invitation-v3',
            function(require) { initComponent.$if($('.software-invitation-v3')) && initComponent(require('hpe-my-account/software-invitation-v3'), $('.software-invitation-v3'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/software-invitation-v3", error); },
            'hpe-my-account/software-invitation-v3'
            );
 initComponent.$if($('.support-alert')) && require.ensure('hpe-my-account/support-alert',
            function(require) { initComponent.$if($('.support-alert')) && initComponent(require('hpe-my-account/support-alert'), $('.support-alert'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/support-alert", error); },
            'hpe-my-account/support-alert'
            );
 initComponent.$if($('.software-alert')) && require.ensure('hpe-my-account/software-alert',
            function(require) { initComponent.$if($('.software-alert')) && initComponent(require('hpe-my-account/software-alert'), $('.software-alert'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/software-alert", error); },
            'hpe-my-account/software-alert'
            );
 initComponent.$if($('welcome-message')) && require.ensure('hpe-my-account/welcome-message',
            function(require) { initComponent.$if($('welcome-message')) && initComponent(require('hpe-my-account/welcome-message'), $('welcome-message'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/welcome-message", error); },
            'hpe-my-account/welcome-message'
            );
 initComponent.$if($('.product-recommendation')) && require.ensure('hpe-my-account/product-recommendation',
            function(require) { initComponent.$if($('.product-recommendation')) && initComponent(require('hpe-my-account/product-recommendation'), $('.product-recommendation'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/product-recommendation", error); },
            'hpe-my-account/product-recommendation'
            );
 initComponent.$if($('.training-recommendation')) && require.ensure('hpe-my-account/training-recommendation',
            function(require) { initComponent.$if($('.training-recommendation')) && initComponent(require('hpe-my-account/training-recommendation'), $('.training-recommendation'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/training-recommendation", error); },
            'hpe-my-account/training-recommendation'
            );
 initComponent.$if($('.sales-representative')) && require.ensure('hpe-my-account/sales-representative',
            function(require) { initComponent.$if($('.sales-representative')) && initComponent(require('hpe-my-account/sales-representative'), $('.sales-representative'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/sales-representative", error); },
            'hpe-my-account/sales-representative'
            );
 initComponent.$if($('hpe-notify')) && require.ensure('hpe-my-account/hpe-notify',
            function(require) { initComponent.$if($('hpe-notify')) && initComponent(require('hpe-my-account/hpe-notify'), $('hpe-notify'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/hpe-notify", error); },
            'hpe-my-account/hpe-notify'
            );
 initComponent.$if($('.contextual-link')) && require.ensure('hpe-my-account/contextual-link',
            function(require) { initComponent.$if($('.contextual-link')) && initComponent(require('hpe-my-account/contextual-link'), $('.contextual-link'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/contextual-link", error); },
            'hpe-my-account/contextual-link'
            );
 initComponent.$if($('.dsb-wrapper')) && require.ensure('hpe-my-account/discover-session-banner',
            function(require) { initComponent.$if($('.dsb-wrapper')) && initComponent(require('hpe-my-account/discover-session-banner'), $('.dsb-wrapper'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/discover-session-banner", error); },
            'hpe-my-account/discover-session-banner'
            );
 initComponent.$if($('hpe-avatar-picker-trigger')) && require.ensure('hpe-my-account/hpe-avatar-picker-initializer',
            function(require) { initComponent.$if($('hpe-avatar-picker-trigger')) && initComponent(require('hpe-my-account/hpe-avatar-picker-initializer'), $('hpe-avatar-picker-trigger'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/hpe-avatar-picker-initializer", error); },
            'hpe-my-account/hpe-avatar-picker-initializer'
            );
 initComponent.$if($('.recommended-events-section')) && require.ensure('hpe-my-account/recommended-events-section',
            function(require) { initComponent.$if($('.recommended-events-section')) && initComponent(require('hpe-my-account/recommended-events-section'), $('.recommended-events-section'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/recommended-events-section", error); },
            'hpe-my-account/recommended-events-section'
            );
 initComponent.$if($('.sei-component')) && require.ensure('hpe-my-account/software-evaluation-invitation',
            function(require) { initComponent.$if($('.sei-component')) && initComponent(require('hpe-my-account/software-evaluation-invitation'), $('.sei-component'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/software-evaluation-invitation", error); },
            'hpe-my-account/software-evaluation-invitation'
            );
 initComponent.$if($('hpe-test-drive-cards')) && require.ensure('hpe-my-account/test-drive-cards',
            function(require) { initComponent.$if($('hpe-test-drive-cards')) && initComponent(require('hpe-my-account/test-drive-cards'), $('hpe-test-drive-cards'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/test-drive-cards", error); },
            'hpe-my-account/test-drive-cards'
            );
 initComponent.$if($('hpe-feedback-wrapper')) && require.ensure('hpe-my-account/feedback-wrapper',
            function(require) { initComponent.$if($('hpe-feedback-wrapper')) && initComponent(require('hpe-my-account/feedback-wrapper'), $('hpe-feedback-wrapper'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/feedback-wrapper", error); },
            'hpe-my-account/feedback-wrapper'
            );

  if ($('welcome-message').length === 0) {
   initComponent.$if($('.content-tooltip')) && require.ensure('hpe-my-account/content-tooltip',
            function(require) { initComponent.$if($('.content-tooltip')) && initComponent(require('hpe-my-account/content-tooltip'), $('.content-tooltip'));;},
            function(error) { require('core/log').error("Can't load hpe-my-account/content-tooltip", error); },
            'hpe-my-account/content-tooltip'
            );
  }
});


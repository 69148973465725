import {HPEIframeModalShared} from 'design-3.0/hpe-iframe-modal.shared';
import {HPEPathFactoryURLUtils} from 'design-3.0/hpe-pathfactory.utils';
import {HPEIframeModalTrigger} from 'design-3.0/hpe-iframe-modal.trigger';

let _isReady = false;

export default {
  initialize: (): void => {
    if (_isReady) return;

    const url = HPEPathFactoryURLUtils.parse();
    url && HPEIframeModalShared.show({url, appendParams: true});

    HPEIframeModalShared.preload();
    HPEIframeModalTrigger.register();

    _isReady = true;
  }
};

import View from 'core/view';
import {SlideCarousel, SlideCarouselEvent} from 'core/slide.carousel';

// @ts-ignore
import Video from 'core/video';

import {scrollToElement} from 'core/helpers/scroll';
import {initComponent} from 'core/helpers/component';
import {setHeaderTheme, Theme} from 'core/helpers/themes';
import {memoize} from '@exadel/esl/modules/esl-utils/decorators';

class StandardMarquee extends SlideCarousel {
  static defaults = Object.assign({}, SlideCarousel.defaults, {
    video: '.js-embedded-video',
    playButton: '.js-slide-video-trigger',
    embeddedVideo: '.embedded-video',
    marqueeItem: '.marquee-slide',
    underlap: '.marquee-underlap',
    activeVideoClass: 'video-active',
    propagateTheme: true,
    refreshCtaGroup: true
  });

  @memoize()
  public get underlap(): boolean {
    return this.$el.is(this.options.underlap);
  }

  init(): void {
    super.init();
    this.reinitializeVideos();
  }

  'on:shared:play'(e: Event): void {
    scrollToElement(this.$el);
    const $slide = $(e.target).closest(this.options.marqueeItem) as JQuery<HTMLElement>;
    if (!$slide.length) return;
    const index = this.$items.index($slide);
    this.to(+index);
  }

  // Video control
  '.embedded-video on:HPE.VIDEO.SHOW'(): void {
    this.applyTheme('dark');
    this.addClass(this.options.activeVideoClass);
  }

  '.embedded-video on:HPE.VIDEO.HIDE'(): void {
    setTimeout(() => {
      this.removeClass(this.options.activeVideoClass);
      this.updateTheme();
    }, Video.VIDEO_TRIGGER_ANIMATION_DELAY_LENGTH);
  }

  '{playButton} on:click'(e: JQuery.ClickEvent): void {
    const $slide = $(e.target).closest(this.options.marqueeItem);
    const $video = $slide.find(this.options.embeddedVideo);
    if ($video) {
      const controller = View.getViewInstance(Video, $video, true);
      controller && controller.play();
    }
  }

  'on:navcarousel:change'(e: CustomEvent): void {
    const $trigger = $(e.detail.trigger);
    const activeIndex = $trigger.attr('data-index');
    if (activeIndex) this.to(+activeIndex);
  }

  onBeforeSlideChange(event: SlideCarouselEvent): void {
    super.onBeforeSlideChange(event);
    this.$active.trigger('collapse');
  }

  protected override onSlideChanged(e: SlideCarouselEvent): void {
    super.onSlideChanged(e);
    this.$active.trigger('collapse');
    const index = this.$items.index(this.$active);
    const $card = this.find(`.nc-card[data-index="${index}"]`);
    $card.click();
  }

  protected override onReinitialize(): void {
    this.reinitializeVideos();
  }

  protected override applyTheme(theme: Theme) {
    super.applyTheme(theme);
    this.underlap && setHeaderTheme(theme);
  }

  protected reinitializeVideos(): void {
    this.find(this.options.video).each(function () {
      const $video = $(this);
      const controller = View.getViewInstance(Video, $video, true);
      if (!controller) {
        initComponent(Video, $video);
      }
    });
  }
}

export default StandardMarquee;

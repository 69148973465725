import {format} from '@exadel/esl/modules/esl-utils/misc/format';
import type {ESLTrigger} from '@exadel/esl/modules/esl-trigger/core';

function updateRegionId($trigger: ESLTrigger) {
  const pattern = $trigger.getAttribute('data-region-id-pattern');
  if (!pattern) return;
  $trigger.setAttribute('data-analytics-region-id', format(pattern, {
    open: $trigger.active ? 'open' : 'close',
    expand: $trigger.active ? 'expand' : 'collapse',
    collapse: $trigger.active ? 'collapse' : 'expand',
    close: $trigger.active ? 'close' : 'open'
  }));
}

export default {
  /**
   * Simple core utility to add dynamic data-analytics-region-id on
   * esl-triggers and esl-triggers based components (e.g. tabs).
   * Define data-region-id-pattern attribute with the pattern that
   * can be consumed by the {@link format} expected attribute definition
   *
   * @example
   *    `data-region-id-pattern="my-reg-id|{expand}"`
   * will produce
   *    `data-analytics-region-id="my-reg-id|expand"`
   * or
   *    `data-analytics-region-id="my-reg-id|collapse"`
   * depending on the state of the trigger
   */
  initialize() {
    customElements.whenDefined('esl-trigger').then(() => {
      $('[data-region-id-pattern]').each((i, el) => updateRegionId(el as ESLTrigger));
    });
    $(document.body)
      .off('esl:change:active.hpe-wc-utils hpe:trigger:initialized')
      .on('esl:change:active.hpe-wc-utils hpe:trigger:initialized', '[data-region-id-pattern]', (event) => {
        updateRegionId(event.target as ESLTrigger);
      });
  }
};

const TEST_MQ = [
  '(touch-enabled)',
  '(-webkit-touch-enabled)',
  '(-moz-touch-enabled)',
  '(-o-touch-enabled)',
  '(-ms-touch-enabled)',
  '(heartz)'
].join(',');

function checkTouchEvents(): boolean {
  // @ts-ignore
  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://github.com/Modernizr/Modernizr/issues/1814
  return matchMedia(TEST_MQ).matches;
}

/**
 * @deprecated incorrect condition to detect touch support, see PointerEvents standard. Consider hover + pointer media check.
 * Copy of legacy modernizr@3.7.0 feature detection.
 */
export const hasTouchEvents = checkTouchEvents();

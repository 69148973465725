import {attr} from '@exadel/esl/modules/esl-utils/decorators';
import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';

import {debug} from 'core/log';

/**
 * Utility to add the query parameters of the current url to the desired link-holder attribute.
 */
export class HPEQueryParamsMixin extends ESLMixinElement {
  public static override is = 'hpe-query-params-mixin';

  /** Attribute to parse the original link, which will be modified. */
  @attr({
    dataAttr: true,
    name: `${HPEQueryParamsMixin.is}-attr`,
    defaultValue: 'href'
  })
  protected attr: string;

  /** Ignored query parameters. */
  @attr({
    dataAttr: true,
    name: `${HPEQueryParamsMixin.is}-ignore`,
    defaultValue: [],
    parser: (s) => s.split(',')
  })
  protected ignore: string[];

  public override connectedCallback(): void {
    super.connectedCallback();
    this.update();
  }

  protected update(): void {
    const hostValue = this.$$attr(this.attr);
    try {
      const hostURL = new URL(hostValue, window.location.origin);
      const {searchParams: usp} = new URL(window.location.href);

      for (const [key, value] of usp) {
        if (this.ignore.includes(key)) continue;
        hostURL.searchParams.set(key, value);
      }
      this.$$attr(this.attr, hostURL.toString());
    } catch (e) {
      debug(`[${HPEQueryParamsMixin.is}]`, e);
    }
  }
}

export default {
  initialize: () => HPEQueryParamsMixin.register()
};

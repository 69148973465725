import {getConfig} from 'core/helpers/config';

const BC_DEFAULT_ACCOUNT_ID = '1160438707001';
const BC_DEFAULT_PLAYER_ID = 'rke4ZwuFNe'; // SJGjOlpoiZ -test, rke4ZwuFNe

export abstract class HPEBrightcoveConstants {
  /* Brightcove Account ID */
  public static get accountId() {
    return getConfig('brightcove.accountId', BC_DEFAULT_ACCOUNT_ID);
  }

  /* Brightcove Default Player ID */
  public static get playerId() {
    return getConfig('brightcove.playerId', BC_DEFAULT_PLAYER_ID);
  }
  /* Brightcove Default Player ID for Audio */
  public static get audioPlayerId() {
    return getConfig('brightcove.audioPlayerId', this.playerId);
  }

  private constructor() {}
}

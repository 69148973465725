import {listen} from '@exadel/esl/modules/esl-utils/decorators';
import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';
import {ESLEventUtils} from '@exadel/esl/modules/esl-utils/dom/events';
import {isElement} from '@exadel/esl/modules/esl-utils/dom';
import {ESLMediaQuery} from '@exadel/esl/modules/esl-media-query/core';

export class HPETrackAnchorMixin extends ESLMixinElement {
  public static is = 'hpe-track-anchor';

  @listen({event: 'esl:show', target: ($that: HPETrackAnchorMixin) => $that.$host})
  protected onAnchorScroll(e: CustomEvent): void {
    const $activator = e.detail?.params?.activator;
    if (!isElement($activator) || !$activator.matches('esl-trigger, esl-tab')) return;
    const shouldPrevent = this.$host?.closest('.uc-vertical-tab-container') && ESLMediaQuery.for('@+LG').matches;
    const detail = {hash: (e.target as HTMLElement).id, preventScroll: shouldPrevent};
    ESLEventUtils.dispatch(window, 'hpe:anchor:hashchange', {detail});
  }
}

export default {
  initialize: () => HPETrackAnchorMixin.register()
};

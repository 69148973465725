var MODULE_ALIAS = 'bundles/compilers/discover.compiler'; 
import {createCompiler} from 'core/core';
import {initComponent} from 'core/helpers/component';

export const compile = createCompiler('discover', ($) => {
  // TODO: Why are you here?
 initComponent.$if($('.elastic-list')) && require.ensure('design-3.0/elastic-list',
            function(require) { initComponent.$if($('.elastic-list')) && initComponent(require('design-3.0/elastic-list'), $('.elastic-list'));;},
            function(error) { require('core/log').error("Can't load design-3.0/elastic-list", error); },
            'design-3.0/elastic-list'
            );
 initComponent.$if($('.js-video-player')) && require.ensure('discover/video-player',
            function(require) { initComponent.$if($('.js-video-player')) && initComponent(require('discover/video-player'), $('.js-video-player'));;},
            function(error) { require('core/log').error("Can't load discover/video-player", error); },
            'discover/video-player'
            );
 initComponent.$if($('.js-video-gallery-v3')) && require.ensure('discover/video-gallery-v3',
            function(require) { initComponent.$if($('.js-video-gallery-v3')) && initComponent(require('discover/video-gallery-v3'), $('.js-video-gallery-v3'));;},
            function(error) { require('core/log').error("Can't load discover/video-gallery-v3", error); },
            'discover/video-gallery-v3'
            );
});

import {getParentPageParam} from 'core/helpers/metrics';
import {HPEBrightcoveConstants} from 'core/providers/brightcove/brightcove-constants';

import type {ESLMedia} from '@exadel/esl/modules/esl-media/core';

/** Get asset id for given element */
export function getAssetId(el: ESLMedia): string {
  return el.getAttribute('analytics-id') || el.getAttribute('media-id') || '';
}

export function getPlayerId(el: ESLMedia): string {
  const playerId = el.getAttribute('player-id');
  switch (el.providerType) {
    case 'brightcove': return playerId || HPEBrightcoveConstants.playerId;
    case 'bc-audio': return playerId || HPEBrightcoveConstants.audioPlayerId;
    default: return null;
  }
}

/** Get default metrics for given element */
export function getDefaultMetrics(el: ESLMedia): Record<string, any> {
  const videoName = el.getAttribute('title');
  const assetId = getAssetId(el);
  const playerType = el.providerType || '';
  const parentPage = getParentPageParam();

  const metrics = {videoName, playerType, assetId, parentPage};

  const originalId = el.getAttribute('media-id');
  if (originalId) Object.assign(metrics, {originalId});

  const playerId = getPlayerId(el);
  if (playerId) Object.assign(metrics, {playerId});

  return metrics;
}

/** Get audio language metrics for given element */
export function getAudioLangMetrics(el: ESLMedia, audioLang: string): Record<string, any> {
  return Object.assign({'link_name' : 'video-audio-language-changed'}, getDefaultMetrics(el), {'videoLanguage' : audioLang});
}

/** Get caption language metrics for given element */
export function getCaptionLangMetrics(el: ESLMedia, captionLang: string): Record<string, any> {
  return Object.assign({'link_name' : 'video-caption-language-changed'}, getDefaultMetrics(el), {'videoLanguage' : captionLang});
}

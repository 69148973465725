import {listen, prop} from '@exadel/esl/modules/esl-utils/decorators';
import {ESLEventUtils, DelegatedEvent} from '@exadel/esl/modules/esl-event-listener/core';

import {URLSearchParamsUtils} from 'core/helpers/url-search-params';

/**
 * Singleton HPE Modal service to handle direct(dynamic) and embedded modals links
 */
export class GenericModalService {
  static is = Symbol('hpe-modal-service');

  public static DIRECT_MODAL_PARAM = 'dmodal';
  public static INDIRECT_MODAL_PARAM = 'emodal';

  @prop(['.sharebox', '.icon-share-global', '.bookmark-btn', '.flip-container .close-icon-sm'])
  public excluded: string[];

  protected constructor() {
    ESLEventUtils.subscribe(this);
  }

  /** Check if the link leads to the same page */
  protected isSamePage($trigger: HTMLAnchorElement) {
    const {pathname, origin} = window.location;
    return $trigger.origin === origin && $trigger.pathname === pathname;
  }

  /** Check if the event happens within excluded elements */
  protected isExcluded(event: DelegatedEvent<Event>) {
    const excluded = this.excluded.join(', ');
    const $exclusion = (event.target as HTMLElement).closest(excluded);
    return $exclusion && event.$delegate ? event.$delegate.contains($exclusion) : !!$exclusion;
  }

  @listen({event: 'click', selector: 'a[href*="?"]', target: document})
  protected _onTriggerClick(e: DelegatedEvent<MouseEvent>): void {
    const $trigger = e.$delegate as HTMLAnchorElement;
    if (!this.isSamePage(e.$delegate as HTMLAnchorElement) || this.isExcluded(e)) return;

    const detail = URLSearchParamsUtils.from($trigger.href).toObject();
    if (detail[GenericModalService.DIRECT_MODAL_PARAM] || detail[GenericModalService.INDIRECT_MODAL_PARAM]) {
      e.preventDefault();
      ESLEventUtils.dispatch(document.body, 'hpe:modal:open', {detail});
    }
  }

  // Static API
  public static init(): void {
    // Singleton HPE service
    const host = document.body as any;
    host[this.is] = host[this.is] || new GenericModalService();
  }

  public static openFromUrl(): void {
    const params = URLSearchParamsUtils.from(window.location.search);
    const open = (type: string) => {
      const detail = {[type]: params.get(type)};
      ESLEventUtils.dispatch(document.body, 'hpe:modal:open', {detail});
    };
    if (params.has(GenericModalService.DIRECT_MODAL_PARAM)) return open(GenericModalService.DIRECT_MODAL_PARAM);
    if (params.has(GenericModalService.INDIRECT_MODAL_PARAM)) return open(GenericModalService.INDIRECT_MODAL_PARAM);
  }
}

var MODULE_ALIAS = 'design-3.0/narrative-init-strategy'; 
import {initComponent} from 'core/helpers/component';

export default {
  initialize: ($) => {
    const notTopicStoryFilter = (i, el) => !$(el).parents('.topic-story').length;
    const modals = $('.narrative-modal').filter(notTopicStoryFilter);
    const narrative = $('.narrative');

    if (modals.length || narrative.length) {
     require.ensure('design-3.0/narrative',
              function(require) { initComponent(require('design-3.0/narrative'), null, {modals, narrative, $});;},
              function(error) { require('core/log').error("Can't load design-3.0/narrative", error); },
              'design-3.0/narrative'
              );
    }
  }
};

;exports.default.componentName = MODULE_ALIAS;
import {error, log} from 'core/log';
import {extendI18nMapping} from 'core/helpers/config';

export default {
  /**
   * Component - Helper to auto-collect additional i18n mappings.
   *
   * Additional mapping can be provided with a special scripts marked with data-i18n-labels
   * ```html
   * <script data-i18n-labels type="application/json">
   *   {
   *     "<key>": "<localized key>"
   *   }
   * </script>
   * ```
   */
  initialize($scope: (sel: string) => JQuery = $) {
    $scope('script[data-i18n-labels]').each(function () {
      try {
        const content = this.innerHTML;
        const labels = JSON.parse(content);
        extendI18nMapping(labels);
        log('[Auto i18n]: i18n extended by ', this);
      } catch (e) {
        error('[Auto i18n]: incorrect mapping ', this, e);
      }
    });
  }
};

import {fireAnalyticsEvent, getParentPageParam} from 'core/helpers/metrics';

const ClickToCall = {
  initialize: () => {
    // Will not subscribe twice due to the same handler reference
    document.body.addEventListener('click', ClickToCall.handleClick, true);
  },

  handleClick: (e: MouseEvent) => {
    if (!e.isTrusted) return;

    const target = e.target as HTMLElement;
    const targetLink = target && target.closest('a[href^=tel]') as HTMLAnchorElement;
    if (targetLink) {
      ClickToCall.track(targetLink.href.split('tel:')[1]);
    }
  },

  track: (phone: string) => {
    fireAnalyticsEvent({
      type: 'hva.clicktocall',
      targetPhone: phone,
      parentPage: getParentPageParam()
    });
  }
};

export default ClickToCall;

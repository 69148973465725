import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';
import {prop, listen} from '@exadel/esl/modules/esl-utils/decorators';
import {rafDecorator} from '@exadel/esl/modules/esl-utils/async/raf';
import {ESLResizeObserverTarget} from '@exadel/esl/modules/esl-utils/dom/events';

export class HPEGradientMixin extends ESLMixinElement {
  static override is = 'hpe-gradient-mixin';

  @prop(0.06) public offsetSize: number;
  @prop(35) public offsetSizeLimit: number;

  protected override connectedCallback(): void {
    super.connectedCallback();
    this.update = rafDecorator(this.update);
    this.update();
  }

  public update() {
    const offset = Math.min(this.$host.clientWidth * this.offsetSize, this.offsetSizeLimit);
    this.$host.style.setProperty('--gradient-offset', `${offset}px`);
  }

  @listen({event: 'resize', target: ESLResizeObserverTarget.for})
  protected onResize() {
    this.update();
  }
}

export default {
  initialize: () => HPEGradientMixin.register()
};

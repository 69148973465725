import {HPEIframeModalShared} from 'design-3.0/hpe-iframe-modal.shared';
import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';
import {attr, listen} from '@exadel/esl/modules/esl-utils/decorators';

export class HPEIframeModalTrigger extends ESLMixinElement {
  public static is = 'hpe-iframe-modal-trigger';

  /** URL to show in the modal */
  @attr({name: HPEIframeModalTrigger.is})
  public directUrl: string;

  /** CSS selector for the ignored inner elements */
  @attr({
    dataAttr: true,
    name: 'iframe-modal-trigger-ignore'
  })
  public ignore: string;

  /** Determines whether the element is not allowed to open the modal */
  protected isIgnored($el: HTMLElement | null): boolean {
    if (!$el || !this.ignore) return false;
    const $ignore = $el.closest(this.ignore);
    return !!$ignore && this.$host.contains($ignore) && $ignore !== this.$host;
  }

  /**
   * Specifies the target url.
   * If the url is not explicitly defined and the $host element is anchor, takes the href value.
   */
  protected get url(): string {
    if (this.directUrl) return this.directUrl;
    if (this.$host instanceof HTMLAnchorElement) return this.$host.href;
    return '';
  }

  @listen('click')
  protected _onClick(e: Event): void {
    const $target = e.target as HTMLElement;
    if (!this.url || this.isIgnored($target)) return;

    e.preventDefault();
    e.stopPropagation();

    HPEIframeModalShared.show({
      url: this.url,
      appendParams: true,
      activator: this.$host
    });
  }
}

import {promisifyTimeout} from '@exadel/esl/modules/esl-utils/async/promise';

export type RequestHeaderOptions = {
  'Accept'?: string;
  'Authorization'?: string;
  'Content-Type'?: string;
  'x-country'?: string;
  'x-language'?: string;
}

export interface RequestOptions {
  method?: string;
  credentials?: string;
  headers?: RequestHeaderOptions;
  timeout?: number;
  emptyResponseBody?: boolean;
  body?: any;
}

export const TIMEOUT_ERROR_MSG = {
  code: 'timeoutError',
  description: 'No response from server, please try again later.'
};

export abstract class PromiseUtils {
  /**
   * Default json helper for fetch API
   */
  static fetchJson(response: Response): Promise<any> {
    if (!response.ok) throw new Error(`${response.url} responded ${response.status} - ${response.statusText}`);
    return response.json();
  }

  /**
   * Default text helper for fetch API
   */
  static fetchText(response: Response): Promise<string> {
    if (!response.ok) throw new Error(`${response.url} responded ${response.status} - ${response.statusText}`);
    return response.text();
  }

  /**
   * Not Found status handler for fetch API
   */
  static handleFetchNotFound(response: Response): Response {
    if (response.status === 404) {
      throw new Error(`${response.status} - ${response.statusText}`);
    }
    return response;
  }

  /**
   * Calls async function in sequence
   * @param lock - object which will contain locks
   * @param fn - function for synchronous call
   * @returns Promise
   */
  static callSynchronous<T>(lock: { running: Promise<T> | undefined }, fn: (...args: any) => Promise<T>): Promise<T> {
    if (lock.running) {
      // wait and try luck again
      return lock.running.then(
        () => PromiseUtils.callSynchronous(lock, fn),
        () => PromiseUtils.callSynchronous(lock, fn)
      );
    }

    return lock.running = fn()
      .then((data) => {
        lock.running = undefined;
        return data;
      }).catch((data) => {
        lock.running = undefined;
        throw data;
      });
  }

  /**
   * Makes fetch with timeout
   * @param url - fetch URL
   * @param options - object with options
   * @param timeoutResponse - object with timeout response
   * */
  static fetchWithTimeout(url: string, options: RequestInit & { timeout?: number }, timeoutResponse: any = TIMEOUT_ERROR_MSG): Promise<Response> {
    const timeoutPromise = promisifyTimeout(options.timeout || 5000, timeoutResponse);

    if ('AbortController' in window) {
      const controller = new AbortController();
      options.signal = controller.signal;
      timeoutPromise.then(() => setTimeout(() => controller.abort(), 1));
    }

    const fetchPromise = fetch(url, options);
    return Promise.race([timeoutPromise, fetchPromise]);
  }

  /**
   * request - wrapper for fetchWithTimeout
   * */
  static request(url: string, options: RequestOptions = {}): Promise<any> {
    options.timeout = options.timeout || 5000;
    options.method = options.method || 'GET';
    return PromiseUtils.fetchWithTimeout(url, options as RequestInit, TIMEOUT_ERROR_MSG)
      .then((response: Response) => {
        return response.json().then((data) => [200, 201].includes(response.status) ? data : Promise.reject(data));
      });
  }
}

export default PromiseUtils;

import 'dotdotdot';
import $ from 'jquery';

import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';
import {isElement, isRelativeNode} from '@exadel/esl/modules/esl-utils/dom';
import {debounce} from '@exadel/esl/modules/esl-utils/async/debounce';
import {attr, boolAttr, listen, memoize} from '@exadel/esl/modules/esl-utils/decorators';
import {ESLResizeObserverTarget, ESLElementResizeEvent} from '@exadel/esl/modules/esl-event-listener/core';

import {isLanguageCode} from 'core/helpers/config';
import {DEFAULT_BP_QUERY, parseAdaptiveValue} from 'core/helpers/breakpoints';

import type {ESLMediaRuleList} from '@exadel/esl/modules/esl-media-query/core';

/**
 * HPE Common utility to initiate dotdotdot truncation
 * NOTE: supports ESLMediaQuery to provide attributes
 * NOTE: do not support attribute query change (on fly in html)
 * The value of hpe-truncate-mixin attribute is treated as adaptive value of the lines or height limit
 */
export class HpeTruncateMixin extends ESLMixinElement {
  static override is = 'hpe-truncate-mixin';

  /** Language codes to use letter wrap strategy */
  static letterTruncateLC = ['ja'];

  /** @readonly */
  @boolAttr({name: 'hpe-truncated'})
  public active: boolean;

  /** Query to activate truncation */
  @attr({name: HpeTruncateMixin.is})
  public lines: string;

  @memoize()
  public get linesQuery(): ESLMediaRuleList<string> {
    return parseAdaptiveValue(this.lines);
  }

  protected override connectedCallback(): void {
    this.$host.innerHTML = this.$host.innerHTML.trim();
    super.connectedCallback();
    this.onQueryChange();
  }

  protected override disconnectedCallback(): void {
    super.disconnectedCallback();
    this.deactivate();
    memoize.clear(this, 'linesQuery');
  }

  protected override attributeChangedCallback() {
    this.deactivate();
    memoize.clear(this, 'linesQuery');
    this.onQueryChange();
  }

  public get truncateOptions() {
    return {wrap: isLanguageCode(HpeTruncateMixin.letterTruncateLC) ? 'letter' : 'word'};
  }

  public activate(): void {
    this.active = true;
    this.truncateDebounced();
    this.$$on(this.onResize);
    this.$$on(this.onUpdate);
  }

  public deactivate(): void {
    this.$$off(this.onResize);
    this.$$off(this.onUpdate);
    this.truncateDebounced.cancel();
    $(this.$host).trigger('destroy');
    this.$host.style.removeProperty('max-height');
    this.active = false;
  }

  public truncate(): void {
    $(this.$host).dotdotdot(this.truncateOptions);
  }
  public truncateDebounced = debounce(this.truncate, 200, this);

  protected lastWidth = 0;
  @listen({auto: false, event: 'resize', target: ESLResizeObserverTarget.for})
  protected onResize({contentRect}: ESLElementResizeEvent): void {
    // Handles only width change, due to possibility of infinite recalculation
    // of height in case of dynamic content inside (footnotes)
    if (this.lastWidth === contentRect.width) return;
    this.lastWidth = contentRect.width;
    this.truncateDebounced();
  }

  /** Observes custom broadcast 'truncate:refresh' event and font loading event to recalculate */
  @listen({auto: false, event: 'fonts:loaded truncate:refresh', target: window})
  protected onUpdate({target}: Event): void {
    if (isElement(target) && !isRelativeNode(target, this.$host)) return;
    this.truncateDebounced();
  }

  /** Observes condition to activate */
  @listen({event: 'change', target: DEFAULT_BP_QUERY})
  protected onQueryChange(): void {
    const len = this.linesQuery.value;
    const lenParsed = parseInt(len);

    this.deactivate();
    if (len === 'none' || lenParsed <= 0) return;
    if (String(lenParsed).length !== len.length) {
      this.$host.style.setProperty('max-height', len);
    } else {
      const limit = parseFloat(getComputedStyle(this.$host).lineHeight) * lenParsed;
      this.$host.style.setProperty('max-height', `${limit}px`);
    }
    this.activate();
  }
}

export default {
  initialize: () => HpeTruncateMixin.register()
};

import {error} from 'core/log';
import {onViewportEnter, onViewportLeave, onceViewportEnter, ViewportStateChangeCallback} from 'core/helpers/viewport';

import type {View} from 'core/view';

export const viewportHook = (event: string, callback: ViewportStateChangeCallback, view: View) => {
  switch (event) {
    case 'change':
      return onViewportEnter(view.$el, callback);
    case 'leave':
      return onViewportLeave(view.$el, callback);
    case 'once':
      return onceViewportEnter(view.$el, callback);
    default:
      error(`Can't bind event. Unknown event type "${event}".`);
  }
};

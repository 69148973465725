var MODULE_ALIAS = 'bundles/compilers/newsroom.compiler'; 
import {createCompiler} from 'core/core';
import {initComponent} from 'core/helpers/component';

export const compile = createCompiler('newsroom', ($) => {
 initComponent.$if($('.newsroom-marquee')) && require.ensure('hpe-newsroom/newsroom-marquee',
            function(require) { initComponent.$if($('.newsroom-marquee')) && initComponent(require('hpe-newsroom/newsroom-marquee'), $('.newsroom-marquee'));;},
            function(error) { require('core/log').error("Can't load hpe-newsroom/newsroom-marquee", error); },
            'hpe-newsroom/newsroom-marquee'
            );
 initComponent.$if($('.subscribe-form')) && require.ensure('hpe-forms/newsroom-subscribe-form-component',
            function(require) { initComponent.$if($('.subscribe-form')) && initComponent(require('hpe-forms/newsroom-subscribe-form-component'), $('.subscribe-form'));;},
            function(error) { require('core/log').error("Can't load hpe-forms/newsroom-subscribe-form-component", error); },
            'hpe-forms/newsroom-subscribe-form-component'
            );
});

import {ESLFootnotes} from '@exadel/esl/modules/esl-footnotes/core';
import {bind, prop} from '@exadel/esl/modules/esl-utils/decorators';

import type {FootnotesItem} from '@exadel/esl/modules/esl-footnotes/core/esl-footnotes-data';

export class HPEFootnotes extends ESLFootnotes {
  public static is = 'hpe-footnotes';

  /** Event to request acknowledgment from {@link HPENote} instances */
  @prop('hpe:note:request') public FOOTNOTE_REQUEST_EVENT: string;
  /** Event to acknowledge {@link HPEFootnotes} instance about footnote */
  @prop('hpe:note:response') public FOOTNOTE_RESPONSE_EVENT: string;

  @bind
  protected _onUpdate(): void {
    super._onUpdate();
    this.addLinkAnalytics();
  }

  protected buildItems(): string {
    const items = this.footnotesList.map((footnote) => this.buildItem(footnote)).join('');
    return items.length > 0 ? `<ul class="esl-footnotes-items centered-content rich-text-container body-copy-small">${items}</ul>` : '';
  }

  protected buildItem(footnote: FootnotesItem): string {
    const item = `${this.buildItemIndex(footnote)}<span>${this.buildItemText(footnote)}${this.buildItemBack(footnote)}</span>`;
    return `<li class="esl-footnotes-item ${(footnote.index.length > 1 ? 'multiple-items' : '')}" data-order="${footnote.index}">${item}</li>`;
  }

  protected buildItemText(footnote: FootnotesItem): string {
    return `<span class="esl-footnotes-text">${footnote.text.trim()}</span>`;
  }

  protected buildItemBack(footnote: FootnotesItem): string {
    return `<span class="esl-back-to-note-wrapper">&#65279;<span class="esl-footnotes-back-to-note" tabindex="0" title="${this.backToNoteLabel}" data-analytics-region-id="footnote_section|return_to_note"></span></span>`;
  }

  protected addLinkAnalytics(): void {
    const links = this.querySelectorAll('.esl-footnotes-text a');

    Array.from(links).forEach((link) => {
      link.setAttribute('data-analytics-region-id', 'footnote_section|link_click');
    });
  }
}

import {getByPath} from '@exadel/esl/modules/esl-utils/misc/object';

export interface IHPEConfig {
  emailSales: {
    formPath: string;
  }
  hybrisApiScript: string;
  i18n: {
    cc: string;
    lc: string;
  }
  ['i18n-labels']: Record<string, string>;
  myAccount: {
    authProvider: string;
    modalSignIn: string;
  }
  pathfactory: {
    modalUrl: string;
    pathfactoryRootUrl: string;
  }
  privacyBannerScript: string;
  validation: {
    email: string;
    partnerDiversions: string;
    phone: string;
  }
  localeCookieStrategy?: 'redirect' | 'rewrite' | 'none';
}

/**
 * Get setting from global HPE config.
 * @param name - path to setting in config
 */
export function getConfig(name: string): any;
export function getConfig<T>(name: string, defaults: T): T;
export function getConfig(name: string, defaults?: any): any {
  return getByPath(window, `[~hpe~].config.${name}`, defaults);
}

const labels = getConfig('i18n-labels', {} as {[key: string]: string});

/**
 * Return i18n known label.
 * In case the key is not presented in the dictionary original key will be returned
 */
export const i18nLabel = (key: string) => labels[key] || key;

/** Extend the list of global i18n mappings */
export const extendI18nMapping = (mapping: Record<string, string>) => {
  if (!mapping || typeof mapping !== 'object') {
    throw new Error('Mapping should be an object of string key-values');
  }
  return Object.assign(labels, mapping);
};

export const getCountryCode = (): string => getConfig('i18n.cc', 'us');
export const getLanguageCode = (): string => getConfig('i18n.lc', 'en');

const isCodeValue = (value: string, name: string | string[]): boolean => {
  return Array.isArray(name) ? name.includes(value) : name === value;
};
export const isCountryCode = (name: string | string[]): boolean => isCodeValue(getCountryCode(), name);
export const isLanguageCode = (name: string | string[]): boolean => isCodeValue(getLanguageCode(), name);

export const getBCP47LocaleFormat = (): string => {
  let country = getCountryCode();
  const lang = getLanguageCode();
  if (country === 'uk') country = 'gb'; // country name for United Kingdom in IETF’s BCP 47 format is gb
  return `${lang}-${country.toUpperCase()}`;
};

export type Locale = {
  country: string;
  language: string;
};

/**
 * return Locale values as populated by AEM system
 * if both, country & language are not available returns default values - us/en
 */
export const pageLocaleValues = (): Locale => {
  return {country: getCountryCode(), language: getLanguageCode()};
};

export const getEmailSalesConfig = (): IHPEConfig['emailSales'] => getConfig('emailSales', {}) as IHPEConfig['emailSales'];

export const getErrorPage = (code: number): string => {
  return `/${getCountryCode()}/${getLanguageCode()}/errorpages/${code}.html`;
};

import {ESLMediaRuleList} from '@exadel/esl/modules/esl-media-query/core';
import {DEFAULT_BP_TUPLE} from 'core/helpers/breakpoints';

export const TAG_NAME = 'hpe-sticky-bar';
const DEFAULT_OFFSETS_TUPLE = '38|38|53|53|53';
const offsetRules = ESLMediaRuleList.parseTuple(DEFAULT_BP_TUPLE, DEFAULT_OFFSETS_TUPLE, parseInt);

export function getStickyBarHeight(): number {
  return (document.querySelector(TAG_NAME) !== null) ? offsetRules.value : 0;
}


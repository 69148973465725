import {format} from '@exadel/esl/modules/esl-utils/misc/format';

import {getConfig} from 'core/helpers/config';
import {URLSearchParamsUtils} from 'core/helpers/url-search-params';

export class HPEPathFactoryURLUtils {
  /** Mandatory query params for opening the modal */
  public static readonly QUERY_PARAMS = ['slug', 'x'];

  protected static getRoot(usp: URLSearchParams): string {
    const formatSource: Record<string, string> = {};
    const pattern = getConfig('pathfactory.pathfactoryRootUrl');

    const isActive = HPEPathFactoryURLUtils.QUERY_PARAMS.every((param) => {
      const value = usp.get(param);
      if (value) formatSource[param] = value;
      return !!value;
    });

    return isActive ? format(pattern, formatSource) : '';
  }

  /** Finds mandatory query parameters, returns formatted PathFactory root url */
  public static parse(): string {
    const usp = URLSearchParamsUtils.from(window.location.search);
    return HPEPathFactoryURLUtils.getRoot(usp);
  }
}

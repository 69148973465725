import {set} from 'core/helpers/object';
import {toCamelCase} from '@exadel/esl/modules/esl-utils/misc/format';

import type JQuery from 'jquery';

export {afterNextRender, rafDecorator} from '@exadel/esl/modules/esl-utils/async/raf';

/** Unwrap JQuery object */
export const unwrap = <T extends HTMLElement = HTMLElement>(el: JQuery<T> | T): T => {
  if (el && 'length' in el) return (el as JQuery)[0] as T;
  return el as T;
};

/** Dispatch native browser Custom Event */
export const dispatchNativeEvent = (
    el: JQuery | HTMLElement,
    name: string,
    options: CustomEventInit = {bubbles: true}
) => {
  const event = new CustomEvent(name, options);
  const _el = unwrap(el);
  _el && _el.dispatchEvent(event);
};

/**
 * Convert string attribute value to the most proper deserialized value (boolean, number, object, string)
 * @param value - attribute value
 * */
export let parseAttrValue = (value: string): any => {
  value = String(value).trim();
  if (value === 'true' || value === '') return true;
  if (value === 'false') return false;
  if (!isNaN(+value)) return +value;
  if (value[0] === '{' && value[value.length - 1] === '}') {
    try {
      return Function(`return ${value};`)();
    } catch (e) { /* Nothing bad, assume that it was object but it is not */ }
  }
  return value;
};

const bindDataAttr = (attr: string, value: any, attrs: Record<string, any>) => {
  value = typeof value === 'string' ? parseAttrValue(value) : value;
  set(attrs, attr.split('--').map(toCamelCase).join('.'), value);
};

/**
 * Parse data attrs on the element
 * Format: data-tabs-trigger-el=".js-trigger" data-tabs-events--show-event = "tabs.on.show"
 * parseDataAttrs(el, 'tabs'); will be transformed into:
 * {
 *  'triggerEl': '.js-trigger',
 *  'events': {
 *    'showEvent': 'tabs.on.show'
 *  }
 * }
 */
export function parseDataAttrs(el: JQuery | HTMLElement, prefix: string = '', attrMapping: Record<string, string> = {}) {
  let domEl = unwrap(el);
  let dataAttrs = {};
  let dataAttrPrefix = prefix ? `data-${prefix}-` : 'data-';
  Array.from(domEl.attributes).forEach(({nodeName, nodeValue}) => {
    if (attrMapping[nodeName]) {
      bindDataAttr(attrMapping[nodeName], nodeValue, dataAttrs);
    } else if (nodeName.indexOf(dataAttrPrefix) === 0) {
      const attrName = nodeName.slice(dataAttrPrefix.length);
      bindDataAttr(attrName, nodeValue, dataAttrs);
    }
  });
  const classList = domEl.classList;
  Object.keys(attrMapping)
    .filter((key) => key.indexOf('.') === 0)
    .forEach((key) => bindDataAttr(attrMapping[key], classList.contains(key.slice(1)), dataAttrs));
  return dataAttrs;
}

/** Stub function to stop event bubbling */
export const stopEventBubbling = (e: Event | JQuery.Event) => e.stopPropagation();
stopEventBubbling.dontChangeContext = true;

/** Clear inner HTML IE safe */
export function clear($el: HTMLElement | JQuery<HTMLElement>) {
  const el = unwrap($el);
  if (!el) return;
  while (el.firstChild) el.removeChild(el.firstChild);
}

/** Render content to $el using render function and data */
export function rerender<T>($el: HTMLElement | JQuery<HTMLElement>, renderer: (data: T) => JSX.Element | HTMLElement | string, data?: T) {
  const el = unwrap($el);
  if (!el) return;
  clear(el);
  const result = renderer(data);
  if (typeof result === 'string') {
    el.innerHTML = result;
  } else {
    el.appendChild(result);
  }
}

/** Render content to $el using render function and collection of data items */
export function rerenderCollection<T>($el: HTMLElement | JQuery<HTMLElement>, renderer: (data: T, index: number) => JSX.Element | HTMLElement | string, data: T[]) {
  const el = unwrap($el);
  if (!el) return;
  clear(el);
  (data || [])
    .map(renderer)
    .map((item: HTMLElement | string) => (typeof item === 'string') ? document.createTextNode(item) : item)
    .forEach((item) => item && el.appendChild(item));
}

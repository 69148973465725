import {promisifyTimeout} from '@exadel/esl/modules/esl-utils/async/promise';
import {HPEAssetViewerLinkMixin} from './modal/asset-viewer-link-mixin';
import {HPEAssetViewerModalShared} from './modal/asset-viewer-modal-shared';

export default {
  initialize: async (): Promise<void> => {
    // split to separate macrotask
    await promisifyTimeout(0);
    HPEAssetViewerLinkMixin.register();
    await HPEAssetViewerModalShared.init();
  }
};

import {getCountryCode, getLanguageCode} from 'core/helpers/config';
import {compileURLTemplate} from 'core/helpers/url-builder';

export class HPEAssetViewerURLUtils {
  protected static readonly QUERY_PARAMS = 'asset-id';

  protected static get url(): URL {
    return new URL(window.location.href);
  }

  public static findAssetSearchParams(): string | null {
    return this.url.searchParams.get(this.QUERY_PARAMS);
  }

  public static getServiceUrl(id: string): string {
    const urlTemplate = '/services/hpe/asset.json?id={id}&cc={cc}&lc={lc}';
    const cc = getCountryCode();
    const lc = getLanguageCode();
    return compileURLTemplate(urlTemplate, {id, cc, lc});
  }

  public static updateUrl(id?: string): void {
    const {url} = this;
    url.searchParams[`${id ? 'set' : 'delete'}`](this.QUERY_PARAMS, id);
    window.history.pushState(null, document.title, url.toString());
  }
}

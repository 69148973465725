import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';
import {attr, listen} from '@exadel/esl/modules/esl-utils/decorators';
import {HPEAssetViewerModalShared} from './asset-viewer-modal-shared';

export class HPEAssetViewerLinkMixin extends ESLMixinElement {
  public static is = 'hpe-asset-viewer-link';

  @attr({name: HPEAssetViewerLinkMixin.is}) public assetId: string;

  protected override connectedCallback(): void {
    super.connectedCallback();
    if (this.$host.tagName === 'A') {
      this.$$attr('href', 'javascript:void(0)');
      this.$$attr('target', null);
      this.$$attr('role', 'button');
    }
  }

  @listen('click')
  protected _onClick(e: MouseEvent): void {
    e.preventDefault();
    e.stopImmediatePropagation();
    HPEAssetViewerModalShared.show({assetId: this.assetId, activator: this.$host});
  }
}

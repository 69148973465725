import * as React from 'jsx-dom';
import {i18nLabel} from 'core/helpers/config';

import type {VideoOverlayConfig} from 'core/video-overlay-types';

interface ArrowLinkProps {
  url: string;
  label: string;
  newWindow?: boolean;
  cssClasses: string;
  attributes: Record<string, string>;
}

interface ShareButtonProps {
  shareLink?: string;
  shareTitle?: string;
}

const escapeTags = (str: string): string => (str || '').replace(/(<([^>]+)>)/ig, '');

const externalize = (link: string): string => link && link.trim().replace(/^\/+/, `${window.location.protocol}//${window.location.host}/`);

export const renderShareButton = (data: ShareButtonProps): JSX.Element => {
  const shareAttributes = {
    'share-url': externalize(data.shareLink),
    'share-title': escapeTags(data.shareTitle) || null
  };
  return (<esl-share className="share-button" {...shareAttributes}>
    <em className="icon-share-global" role="presentation"></em>
    <span className="share-text body-copy-small">{i18nLabel('Share')}</span>
  </esl-share>);
};

export const renderArrowLink = (data: ArrowLinkProps): JSX.Element => {
  const words = (data.label || '').trim().split(' ');
  const lastWord = words.pop();
  const attrs = data.attributes || {};
  // Clean empty attributes
  Object.keys(attrs).filter((key) => !attrs[key]).forEach((key) => delete attrs[key]);
  const cssClasses = 'vim-link hpe-arrow-link no-js typo4 ' + (data.cssClasses || '');

  return (<a href={data.url} target={data.newWindow && '_blank'} className={cssClasses} {...attrs}>
    {words.join(' ')} <span className="arrow-wrapper">{lastWord}<div className="arrow"/></span>
  </a>);
};

export const renderDescription = (content: string, truncate: boolean): JSX.Element => {
  if (truncate) {
    return (<div className="vim-description js-text-collapsible" data-tc-rows="2">
      <div className="text-collapsible rich-text-container" innerHTML={content}> </div>
      <button className="tc-trigger">
        <span className="label-collapsed">{i18nLabel('Show more')}</span>
        <span className="label-expanded">{i18nLabel('Show less')}</span>
      </button>
    </div>);
  }
  return (<div className="vim-description rich-text-container" innerHTML={content}> </div>);
};

export const renderInfoModule = (data: VideoOverlayConfig): JSX.Element => {
  const content = [];
  if (data.title) {
    content.push((<h4 className="vim-title">{data.title}</h4>));
  }
  if (data.extendedView && data.description) {
    content.push(renderDescription(data.description, data.truncateDescription));
  }
  const vimLinks = (data.links || []).filter((link) => link && link.url);
  if (data.extendedView && vimLinks.length) {
    content.push((<div className="vim-links">{vimLinks.map(renderArrowLink)}</div>));
  }
  if (data.shareLink) {
    content.push((<div className="vim-utility-container">{renderShareButton(data)}</div>));
  }
  return (<div className="video-info-module">{content}</div>);
};

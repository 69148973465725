import {ESLMediaQuery} from '@exadel/esl/modules/esl-media-query/core';

// For compatibility
export {reverseTheme, setHeaderTheme} from 'core/helpers/themes';

export function isGNV4Enabled(): boolean {
  return !!document.querySelector('gn-header');
}

export function isSNV4Enabled(): boolean {
  return !!document.querySelector('gn-header')?.hasAttribute('has-secondary');
}

export function isSNV4CtaEnabled(): boolean {
  return !!document.querySelector('gn-header')?.hasAttribute('has-secondary-cta');
}

export function getStickyHeaderHeight() {
  if (ESLMediaQuery.for('@-MD').matches) return 0;
  const header = document.querySelector<HTMLElement>('gn-header[sticky]');
  if (!header) return 0;
  // As secondary V4 nav should stick without primary V4 nav
  const target = isSNV4Enabled() ? header.querySelector<HTMLElement>('.gn-nav-secondary') : header;
  const dividerHeight = target.querySelector<HTMLElement>('.gn-divider-bar')?.offsetHeight || 0;
  return target.offsetHeight + dividerHeight;
}

// Initial ESL config
import 'core/config/esl.config';

import {onDocumentReady} from '@exadel/esl/modules/esl-utils/dom/ready';

import {ESLToggleableDispatcher, ESLToggleable} from '@exadel/esl/modules/esl-toggleable/core';
import {ESLPanel} from '@exadel/esl/modules/esl-panel/core';
import {ESLPanelGroup} from '@exadel/esl/modules/esl-panel-group/core';
import {ESLTrigger} from '@exadel/esl/modules/esl-trigger/core';
import {ESLTab, ESLTabs} from '@exadel/esl/modules/esl-tab/core';
import {ESLScrollbar} from '@exadel/esl/modules/esl-scrollbar/core';
import {ESLDragToScrollMixin} from '@exadel/esl/modules/esl-drag-to-scroll/core';
import {ESLSelectList} from '@exadel/esl/modules/esl-forms/esl-select-list/core';
import {ESLSelect} from '@exadel/esl/modules/esl-forms/esl-select/core';
import {ESLAlert} from '@exadel/esl/modules/esl-alert/core';
import {ESLA11yGroup} from '@exadel/esl/modules/esl-a11y-group/core';
import {ESLPopup} from '@exadel/esl/modules/esl-popup/core';
import {ESLTooltip} from '@exadel/esl/modules/esl-tooltip/core';
import {ESLAnimateMixin} from '@exadel/esl/modules/esl-animate/core';
import {ESLRelatedTarget} from '@exadel/esl/modules/esl-related-target/core';
import {ESLOpenState} from '@exadel/esl/modules/esl-open-state/core';
import {ESLVSizeCSSProxy} from '@exadel/esl/modules/esl-utils/fixes/viewport';

import {HPEAvatar} from 'hpe-wc/hpe-avatar';
import {HPEFootnotes} from 'hpe-wc/hpe-footnotes';
import {HPENote} from 'hpe-wc/hpe-note';

// TODO: A lot of extra staff - cleanup/lazyload
onDocumentReady(() => {
  // 100vh fix
  ESLVSizeCSSProxy.observe();

  // ESL Components
  ESLToggleableDispatcher.init();
  ESLToggleable.register();

  ESLPanelGroup.register();
  ESLPanel.register();

  ESLTrigger.register();
  ESLTab.register();
  ESLTabs.register();

  ESLScrollbar.register();
  ESLDragToScrollMixin.register();

  ESLPopup.register();
  ESLTooltip.register();

  ESLAlert.register();
  ESLAlert.init();

  ESLA11yGroup.register();

  // HPE Web Components
  HPEAvatar.register();

  ESLSelectList.register();
  ESLSelect.register();

  HPEFootnotes.register();
  HPENote.register();

  ESLAnimateMixin.register();
  ESLRelatedTarget.register();
  ESLOpenState.register();
});

// GN v4 init
import(/*webpackChunkName: "hpe-gn/gn-header-v4"*/'hpe-gn/gn-header-v4');
import(/*webpackChunkName: "hpe-gn/search-box"*/'hpe-gn/search-box');

// Defer third-priority modules
import(/* webpackChunkName: "hpe-share-async" */'core/hpe-share');

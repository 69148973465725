export interface ElementBounds {
  elementTop: number;
  elementHeight: number;
  elementBottom: number;
  viewportTop: number;
  screenHeight: number;
  viewportBottom: number;
  inViewport: boolean;
}

export type ExtremeFunction = 'min' | 'max';
export type ExtremeProperty = 'height' | 'width' | 'innerHeight' | 'innerWidth' | 'outerHeight';

export const getExtremeItemsProp = ($items: JQuery<HTMLElement>, prop: ExtremeProperty = 'height', resetClass = 'geipreset', fn: ExtremeFunction = 'max'): number => {
  let val = 0;
  $items.addClass(resetClass);
  $items.each((i, item) => {
    const $item = $(item);
    val = Math[fn](val, $item[prop]());
  });
  $items.removeClass(resetClass);
  return val;
};

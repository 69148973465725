import $ from 'jquery';
import {debug} from 'core/log';
import {parseDataAttrs, unwrap} from 'core/helpers/dom';
import {ViewRegistry} from 'core/view.registry';
import {bindEvent, isEventQuery, getAllEventProperties} from 'core/view.event-manager';
import {sequentialUID} from '@exadel/esl/modules/esl-utils/misc/uid';

import type {ESLDomElementRelated} from '@exadel/esl/modules/esl-utils/abstract/dom-target';

export interface ViewOptions extends Record<string, any> {
  el?: string | HTMLElement | JQuery<HTMLElement>;
}

export type EventCallback = (e?: any, ...rest: any[]) => void; // Any as it depends on event type and hooks
export type EventActionDefinition = string | EventCallback;
export type UnsubscriberDefinition = string | EventCallback;

/**
 * Legacy HPE base element implementation
 *
 * Prefer {@link ESLBaseElement} or {@link ESLMixinElement} for new components
 *
 * Please note that:
 *  - JQuery usage is not a blocker for ESL please be aware that
 *    - ESL Event Listeners can catch JQuery events, see {@link JQTarget}
 *    - There is no any technical limitations to use JQuery traversing / manipulations / utils inside ESL elements
 *    (in case we cannot avoid it)
 *  - Please also be aware that all the ViewEventManger tricks have a replacement in ESLEventListeners (see https://esl-ui.com/core/esl-event-listener/)
 *  - And finally be aware that View implements {@link ESLDomElementRelated} but IS NOT GOING TO SUPPORT ESL Listeners auto-subscription
 *
 *  @deprecated please note that according to agreement on UI dev calls usage of View is not recommended
 */
export class View <T extends ViewOptions = ViewOptions> implements ESLDomElementRelated {
  /** Init when element enters the viewport */
  static supportAsyncInit = false;

  /** Get instance of initialized View on the element */
  static getViewInstance = ViewRegistry.getInstance;

  /** Delay to cleanup view own keys */
  static gcTimeout: number = 500;

  /** data attributes prefix to bind options */
  static dataAttrs?: string;

  /** Default options*/
  static defaults: ViewOptions = {};

  /**
   * Attrs => options map.
   * By default using core/helpers/dom#parseDataAttrs parser
   */
  static attrsOptionsMap: Record<string, string> = {};

  public readonly $el: JQuery<HTMLElement>;
  public readonly rawHTML: string;

  public readonly cid: string;

  public options: T;

  private readonly unsubscribers = new Map<string, UnsubscriberDefinition[]>();

  /**
   * Provide debug information about View class name
   * */
  public get viewName() {
    return (this.constructor as any).className || (this.constructor as any).componentName || this.constructor.name;
  }

  /**
   * Provide compatibility with ESL (listeners, decorators)
   * */
  public get $host(): HTMLElement {
    return unwrap(this.$el);
  }

  /**
   * View Constructor. Don't override it without big necessary
   * Use init function for your logic
   * @param options {Object}
   */
  constructor(options: Partial<T> = {}) {
    this.cid = sequentialUID('view');
    if (!options.el) throw (new ReferenceError('options.el is not defined'));
    this.$el = $(options.el as any); // Type checked in interface definition
    this.rawHTML = this.$el.get(0).outerHTML;
    this.options = this._initOptions(options);
    ViewRegistry.register(this);
    this.bindEvents();
    this.init(options);
    this.$el.trigger('hpe-view-initialized', this);
  }

  /** If you don't need this view at all - destroy it(remove all references and events). */
  destroy() {
    setTimeout(() => this.$el && this.onDestroy(), 1);
  }

  /** Remove all unused stuff */
  onDestroy() {
    let element = this.getUniqInfoAboutElement();
    this.$el.trigger('hpe-view-before-destroy', this.cid); // Notification for other components

    // Major action: unbinding event
    this.unbindEvents();

    // Secondary action: view own keys removing after delay
    setTimeout(
      () => Object.keys(this).forEach((key) => delete (this as any)[key]),
      (this.constructor as typeof View).gcTimeout
    );

    this.$el.trigger('hpe-view-after-destroy', this.cid);

    debug(`${this.viewName} Component: was removed from `, element);
  }

  /** Create options object. Value is based on dom attrs and defaults */
  _initOptions(options: Partial<T> = {}): T {
    delete options.el;
    const viewType = this.constructor as typeof View;
    const attrOptions = parseDataAttrs(this.$el.get(0), viewType.dataAttrs, viewType.attrsOptionsMap);
    return $.extend(true, {}, viewType.defaults, options, attrOptions) as T;
  }

  /**
   * Initialization
   * @param options
   */
  init(options: Partial<T>) {
    // Declare your init logic here. First arguments will be options
  }

  /** Attach event to view */
  bindEvents(): void;
  bindEvents(eventQuery: string, callback: EventActionDefinition): void;
  bindEvents(eventQuery?: string, callback?: EventActionDefinition) {
    if (!eventQuery) {
      this.unbindEvents();
      getAllEventProperties(this).forEach((event: string) => bindEvent(this, event, (this as any)[event]));
    } else {
      if (!isEventQuery(eventQuery)) throw new TypeError('wrong event query');
      if (!callback) throw new ReferenceError('callback is not defined');
      bindEvent(this, eventQuery, callback);
    }
  }

  /** Detach event from view */
  unbindEvents(eventQuery?: string) {
    if (arguments.length === 0) {
      this.unsubscribers.forEach((list, event) => this.unbindEvents(event));
    } else {
      const unsubList = this.unsubscribers.get(eventQuery);
      (unsubList || []).forEach((off: UnsubscriberDefinition) => {
        if (typeof off === 'string') this.$el.off(off);
        if (typeof off === 'function') off();
      });
      this.unsubscribers.delete(eventQuery);
    }
  }

  /** Add event unsubscriber */
  registerEventUnsubscribe(event: string, off: UnsubscriberDefinition) {
    const unsubList = this.unsubscribers.get(event) || [];
    unsubList.push(off);
    this.unsubscribers.set(event, unsubList);
  }

  /** Get attribute */
  getAttribute(attr: string) {
    return this.$el.attr(attr);
  }

  /** Get uniq information about element. Useful for debugging */
  getUniqInfoAboutElement() {
    let id = this.getAttribute('id');
    return id ? `#${id}` : this.$el.get(0);
  }

  /** Proxy for this view.$el.find */
  find(sel: string | HTMLElement | JQuery) {
    return this.$el.find(sel);
  }

  /** Add class(es) to root element */
  addClass(cls: string | string[]) {
    return this.$el.addClass(cls);
  }
  /** Add class(es) to root element */
  hasClass(cls: string) {
    return this.$el.hasClass(cls);
  }
  /** Remove class(es) from root element */
  removeClass(cls: string | string[]) {
    return this.$el.removeClass(cls);
  }
  /** Toggle class(es) from root element */
  toggleClass(cls: string | string[], force?: boolean) {
    return this.$el.toggleClass(cls, force);
  }
}

export default View;

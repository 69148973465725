import {debug} from 'core/log';
import FontFaceObserver from 'fontfaceobserver';

const TIMEOUT = 10000;
const FONTS_EVENT = new CustomEvent('fonts:loaded');

const fonts = (() => {
  switch (document.documentElement.lang.toLowerCase()) {
    case 'ja': return [
      'AxisProN-Regular'
    ];
    case 'ko': return [
      'YDGothic_120 Pro'
    ];
    default: return [
      'Metric Regular',
      'Metric Light',
      'Metric Semibold',
      'Metric Medium'
    ];
  }
})();

const fonts$ = fonts.map((fontFamily) => new FontFaceObserver(fontFamily));
const fontsPromises = fonts$.map((fontObserver) => fontObserver.load(null, TIMEOUT));
export const fontsLoaded = Promise.all(fontsPromises);

fontsPromises.forEach((loadingPromise) => {
  loadingPromise.then((fo: any /* trust me, everything that logged is inside */) => {
    debug(`System: Font "${fo.family}, ${fo.style}, ${fo.weight}" is loaded.`);
  });
});

fontsLoaded.then(() => window.dispatchEvent(FONTS_EVENT));

export default fontsLoaded;

import {copyDefinedKeys, parseNumber} from '@exadel/esl/modules/esl-utils/misc';

import type {VideoOverlayConfig} from 'core/video-overlay-types';

interface ParseMediaConfig {
  param: string;
  allowResource?: boolean;
}

export class VideoUtils {
  public static mediaIdParam = 'media-id';
  protected static mediaStrategyParam = 'media-strategy';
  protected static startTimeParam = 't';
  protected static directParamRegex = /(https|ibm|youtube|brightcove|bc-audio)[.:](.+)/i;

  public static mediaStrategies = {
    embed: 'embed',
    overlay: 'overlay',
    delegate: 'delegate'
  };

  /** @return {boolean} if media type is audio */
  public static isAudio(mediaType: string): boolean {
    return (mediaType || '').indexOf('audio') !== -1;
  }

  /**
   * Build media id param. param = resourceUrl | mediaType.mediaId
   * @param {VideoConfigMandatory} options
   * @return {string}
   */
  public static buildMediaIdParam(options: VideoOverlayConfig): string {
    const {id, resource, type} = options;
    if (resource) return resource;
    if (type && id) return `${String(type).toLowerCase()}.${id}`;
    return '';
  }

  /**
   * Build media strategy param
   * @param {VideoConfigMandatory} options
   * @return {string}
   */
  protected static buildMediaStrategyParam(options: VideoOverlayConfig): string {
    const {strategy} = options;
    return Object.values(this.mediaStrategies).includes(strategy) ? strategy : '';
  }

  /**
   * Parse media entity from the given url part
   * @param {string} param
   * @param {boolean} [allowResource] - allow resourceUrl syntax
   */
  protected static parseMediaEntity(cfg: ParseMediaConfig): VideoOverlayConfig | null {
    const {param, allowResource = true} = cfg;
    if (!param) return null;
    let startTime = this.getURLStartTime();
    if (param.endsWith('.json') && allowResource) return {resource: param, startTime};
    const directParam = param.match(VideoUtils.directParamRegex);
    if (directParam) {
      const [, mediaType, mediaId] = directParam;
      return {id: mediaId, type: mediaType.toLowerCase(), startTime};
    }
    return null;
  }

  /**
   * Parse media-strategy url param
   * @param {URLSearchParams} usp
   * @returns {string|null}
   */
  protected static parseMediaStrategy(usp: URLSearchParams = new URLSearchParams(location.search)): string | null {
    return usp.get(this.mediaStrategyParam);
  }

  /**
   * Parse media-id url param
   * @param {URLSearchParams} usp
   * @returns {string|null}
   */
  public static parseMediaId(usp: URLSearchParams = new URLSearchParams(location.search)): string | null {
    return usp.get(this.mediaIdParam);
  }

  /**
   * Parse start time url param
   * @param {URLSearchParams} usp
   * @returns {number}
   */
  protected static getURLStartTime(usp: URLSearchParams = new URLSearchParams(location.search)): number | undefined {
    const value = usp.get(this.startTimeParam);
    return parseNumber(value);
  }

  /**
   * Build media share link
   * @param {VideoOverlayConfig} options
   * @return string
   */
  public static buildMediaUrl(options: VideoOverlayConfig): string {
    const url = new URL(window.location.href);
    const id = this.buildMediaIdParam(options);
    const strategy = this.buildMediaStrategyParam(options);

    id && url.searchParams.set(this.mediaIdParam, id);
    options.startTime && url.searchParams.set(this.startTimeParam, '' + options.startTime);
    if (strategy) {
      url.searchParams.set(this.mediaStrategyParam, strategy);
    } else {
      // Fallback to remove initial strategy (no strategy === default overlay strategy)
      url.searchParams.delete(this.mediaStrategyParam);
    }
    return url.toString();
  }

  public static applyDefaultOptions(options: VideoOverlayConfig): VideoOverlayConfig {
    options = Object.assign({}, options);
    options.title = options.title || 'Video';
    options.truncateDescription = true;
    options.analyticsId = options.analyticsId || options.id;
    options.shareTitle = options.title;
    options.shareDescription = options.description;
    options.shareLink = options.shareLink || this.buildMediaUrl(options);
    return options;
  }

  /** Merge options objects */
  public static mergeOptions(config: VideoOverlayConfig, source: VideoOverlayConfig): VideoOverlayConfig {
    return {...copyDefinedKeys(config), ...source};
  }

  /**
   * Parse full media config from the url
   * @returns {VideoConfigMandatory} config
   */
  public static getMediaConfig(): VideoOverlayConfig {
    const path = location.pathname;
    const query = new URLSearchParams(location.search);
    const aemSelectors = path.substring(path.indexOf('.') + 1);

    const strategy = this.parseMediaStrategy(query);
    const mediaEntity = this.parseMediaEntity({
      param: VideoUtils.parseMediaId(query) || aemSelectors,
      allowResource: query.has(VideoUtils.mediaIdParam)
    });

    return mediaEntity ? {
      strategy,
      ...mediaEntity
    } : null;
  }

  /**
   * Update url with "media" params
   * @param {VideoOverlayConfig} options
   */
  public static updateUrl(options: VideoOverlayConfig): void {
    const openStateUrl = this.buildMediaUrl(options);
    window.history.replaceState(null, document.title, openStateUrl);
  }

  /**
   * Clear url from "media" params
   */
  public static clearUrl(): void {
    const url = new URL(window.location.href);
    url.searchParams.delete(this.mediaIdParam);
    url.searchParams.delete(this.mediaStrategyParam);
    url.searchParams.delete(this.startTimeParam);
    window.history.replaceState(null, document.title, url.toString());
  }
}

import {rafDecorator} from '@exadel/esl/modules/esl-utils/async/raf';
import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';
import {prop, memoize, decorate} from '@exadel/esl/modules/esl-utils/decorators';

import {isAEMAuthorMode} from 'core/helpers/aem';

/** Mixin to create footer parallax effect */
class GNFooterParallaxMixin extends ESLMixinElement {
  static is = 'gn-footer-parallax';

  @prop(25 /* % */) public PARALLAX_HEIGHT: number;

  @memoize()
  public get $target(): HTMLElement {
    return this.$host.querySelector('.hf-parallax-wrapper');
  }

  protected override connectedCallback(): void {
    super.connectedCallback();
    this.update();
    this.$$on({event: 'resize scroll', target: window}, this.update);
  }
  protected override disconnectedCallback(): void {
    super.disconnectedCallback();
    this.$target.style.removeProperty('top');
  }

  @decorate(rafDecorator)
  public update(): void {
    if (!this.$target) return;

    const bounds = this.$host.getBoundingClientRect();
    const offset = bounds.top + bounds.height - window.innerHeight; // px
    const relativeOffset = Math.min(1, Math.max(0, offset) / bounds.height); // %, normalized
    const parallaxOffset = this.PARALLAX_HEIGHT * (relativeOffset - 1);

    this.$target.style.top = `${parallaxOffset}%`;
  }
}

export default {
  initialize: () => !isAEMAuthorMode() && GNFooterParallaxMixin.register()
};

import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';
import {ENTER} from '@exadel/esl/modules/esl-utils/dom/keys';
import {attr, prop, listen, boolAttr} from '@exadel/esl/modules/esl-utils/decorators';

import {VideoOverlay} from 'core/video-overlay';

/**
 * Mixin to trigger video overlay
 *
 * `hpe-video-overlay` - the attribute to initialize video overlay trigger
 *
 * A value of this attribute can be an asset details endpoint:
 * ```html
 * <a hpe-video-overlay="/data/video.json"> ... </a>
 * ```
 *
 * or type and asset identifier:
 * ```html
 * <a hpe-video-overlay="youtube:8tbNdGa2iS0"> ... </a>
 * <a hpe-video-overlay="brightcove:8E1D3C66-E54A"> ... </a>
 * ```
 *
 * Additional attributes:
 * - `data-attribute-video-title` - asset title, using to provide trigger a11y
 *
 * In case of using the second syntax, it is possible to use additional attributes consumed by the mixin:
 * `data-attribute-video-player-id` - player identifier
 * `data-attribute-video-account-id` - account id for brightcove videos (used by p13n team)
 * `data-attribute-video-link` - share link
 * `data-attribute-asset-id` - analytics id
 * `data-attribute-start-time` - start time in seconds
 */

export class HpeVideoOverlayMixin extends ESLMixinElement {
  public static is = 'hpe-video-overlay';
  public static disableAttr = HpeVideoOverlayMixin.is + '-disabled';

  @prop('esl-share, .bookmark-btn') protected ignoreChild: string;

  @attr({name: HpeVideoOverlayMixin.is}) public videoAttr: string;
  @attr({name: 'data-attribute-video-title'}) public title: string;
  @attr({name: 'data-attribute-video-player-id'}) public playerId: string;
  @attr({name: 'data-attribute-video-account-id'}) public accountId: string;
  @attr({name: 'data-attribute-video-link'}) public shareLink: string;
  @attr({name: 'data-attribute-asset-id'}) public analyticsId: string;
  @attr({name: 'data-attribute-start-time', parser: parseInt}) public startTime: number;

  @boolAttr({name: HpeVideoOverlayMixin.disableAttr}) public disabled: boolean;

  protected override connectedCallback(): void {
    super.connectedCallback();
    this.initTrigger();
  }

  protected initTrigger(): void {
    if (this.$host.tagName === 'A') {
      this.$$attr('href', 'javascript:void(0)');
      this.$$attr('target', null);
      this.$$attr('role', this.$$attr('role') || 'button');
    }

    // a11y
    if (typeof this.$$attr('aria-label') !== 'string') {
      this.$$attr('aria-label', this.title || 'Play video');
    }
  }

  public async openOverlay(): Promise<void> {
    const [type, ...rest] = this.videoAttr.split(':');
    const id = rest.join(':');

    if (ESL.Media.supports(type)) {
      const {title, playerId, accountId, shareLink, analyticsId, startTime} = this;

      await VideoOverlay.open({id, type, title, playerId, accountId, shareLink, analyticsId, startTime});
    } else {
      await VideoOverlay.open({resource: this.videoAttr, startTime: this.startTime});
    }

    this.$host.focus({preventScroll: true});
  }

  @listen('click')
  protected _onClick(e: MouseEvent): void {
    if (this.disabled) return;

    const $target = e.target as HTMLElement;
    if (this.$host.contains($target.closest(this.ignoreChild))) return;

    e.preventDefault();
    e.stopImmediatePropagation();
    this.openOverlay();
  }

  @listen('keydown')
  protected _onKeydown(e: KeyboardEvent): void {
    if (this.disabled) return;

    const $target = e.target as HTMLElement;
    if (this.$host.contains($target.closest(this.ignoreChild))) return;

    if (e.key !== ENTER) return;
    e.stopPropagation();
    e.preventDefault();
    this.$host.click();
  }
}

export default {
  initialize: () => HpeVideoOverlayMixin.register()
};

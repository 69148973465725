import {debug} from 'core/log';
import {unwrap} from 'core/helpers/dom';
import {memoize} from '@exadel/esl/modules/esl-utils/decorators/memoize';

import type {HPEIframeModal, IframeModalActionParams} from 'design-3.0/hpe-iframe-modal';

/** Shared {@link HPEIframeModal} */
export class HPEIframeModalShared {
  @memoize()
  public static async init(): Promise<HPEIframeModal> {
    try {
      const {HPEIframeModal} = await import(/*webpackChunkName: "design-3.0/hpe-iframe-modal"*/'design-3.0/hpe-iframe-modal');
      HPEIframeModal.register();
      return HPEIframeModal.$modal;
    } catch (e) {
      debug('[HPE Iframe Modal]', e);
    }
  }

  public static preload($scope: JQuery<HTMLElement> | HTMLElement = document.body): void {
    if (unwrap($scope)?.querySelector('[hpe-iframe-modal-trigger]')) {
      HPEIframeModalShared.init();
    }
  }

  public static show(params: IframeModalActionParams): void {
    HPEIframeModalShared.init().then(($modal) => $modal.show(params));
  }

  public static hide(params: IframeModalActionParams): void {
    HPEIframeModalShared.init().then(($modal) => $modal.hide(params));
  }
}

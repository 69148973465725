import {getBCP47LocaleFormat} from 'core/helpers/config';

type FormatTypes = 'sm' | 's';
const LOCALE_FORMATS: Record<string, Record<FormatTypes, string>> = {
  'tr-TR': {sm: 'd.mm.yy', s: 'd.mm.yy'},
  'ru-RU': {sm: 'd.mm.yy', s: 'd.mm.y'},
  'de-DE': {sm: 'd.mm.yy', s: 'd.mm.y'},
  'fr-CH': {sm: 'd.mm.yy', s: 'd.mm.y'},
  'de-CH': {sm: 'd.mm.yy', s: 'd.mm.y'},
  'de-AT': {sm: 'd.mm.yy', s: 'd.mm.y'},

  'fr-CA': {sm: 'yy-mm-d', s: 'y-mm-d'},
  'sv-SE': {sm: 'yy-mm-d', s: 'yy-mm-d'},

  'en-SG': {sm: 'd/m/yy', s: 'd/m/y'},
  'en-IN': {sm: 'd/m/yy', s: 'd/m/y'},

  'zh-TW': {sm: 'yy/m/d', s: 'yy/m/d'},
  'en-ZA': {sm: 'yy/mm/d', s: 'yy/mm/d'},
  'en-US': {sm: 'm/d/yy', s: 'm/d/y'},

  'ko-KR': {sm: 'yy. m. d', s: 'y. m. d'},
  'ja-JP': {sm: 'yy年m月d日', s: 'yy年m月d日'},
  'nl-NL': {sm: 'd-m-yy', s: 'd-m-y'},
  'zh-CN': {sm: 'yy-m-d', s: 'y-m-d'}
};
const LOCALE_FORMAT_DEFAULT = {sm: 'd/mm/yy', s: 'd/mm/y'};

const leadingZero = (n: number) => (n < 10 ? '0' : '') + n;

/**
 * Format string using Intl.DateTimeFormat and current locale
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat DateTimeFormatOptions for details
 * */
export function formatDate(dateStr: string, type: FormatTypes = 'sm', locale = getBCP47LocaleFormat()): string {
  if (!dateStr) return dateStr;
  try {
    const date = new Date(dateStr);
    const format = (LOCALE_FORMATS[locale] || LOCALE_FORMAT_DEFAULT)[type];
    return format.replace(/(d|m+|y+)/g, (group: string) => {
      switch (group) {
        case 'd': return String(date.getDate());
        case 'm': return String(date.getMonth() + 1);
        case 'mm': return leadingZero(date.getMonth() + 1);
        case 'y': return String(date.getFullYear() % 100);
        case 'yy': return String(date.getFullYear());
        default: return '';
      }
    });
  } catch {
    return dateStr;
  }
}

/** Check if passed string correspond to the date format */
export const isDate = (str: string, format: string): boolean => {
  switch (format) {
    case 'YYYY-MM-DD':
      // ISO 8601 Date
      return /^(?:[1-9]\d{3}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1\d|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[1-9]\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00)-02-29)$/.test(str);
  }
  return false;
};

const MillisecondsInMinute = 60 * 1000;
const MillisecondsInHour = 60 * 60 * 1000;
const MillisecondsInDay = 24 * 60 * 60 * 1000;

/** Converts minutes into milliseconds */
export const minToMs = (minutes: number): number => {
  return minutes * MillisecondsInMinute;
};

/** Converts milliseconds into minutes */
export const msToMin = (milliseconds: number): number => {
  return milliseconds / MillisecondsInMinute;
};

/** Converts hours into milliseconds */
export const hrsToMs = (hours: number): number => {
  return hours * MillisecondsInHour;
};

/** Converts milliseconds into hours */
export const msToHrs = (milliseconds: number): number => {
  return milliseconds / MillisecondsInHour;
};

/** Converts days into milliseconds */
export const dayToMs = (days: number): number => {
  return days * MillisecondsInDay;
};

/** Converts milliseconds into days */
export const msToDay = (milliseconds: number): number => {
  return milliseconds / MillisecondsInDay;
};

/** Counts days between passed and current dates */
export const daysTillDate = (date: string): number => {
  return Math.ceil(msToDay(new Date(date).getTime() - Date.now()));
};

const A11Y_MODE_CLASS = 'disabled-focus';
const a11yModeListeners = new Set();

export const isA11yMode = () => !document.body.classList.contains(A11Y_MODE_CLASS);
export const enableA11yMode = () => {
  document.body.classList.remove(A11Y_MODE_CLASS);
  a11yModeListeners.forEach((cb: () => void) => cb());
  a11yModeListeners.clear();
};
export const disableA11yMode = () => document.body.classList.add(A11Y_MODE_CLASS);

export const onceA11yMode = (callback: () => void) => {
  if (isA11yMode()) callback();
  else a11yModeListeners.add(callback);
};

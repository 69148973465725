import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';
import {listen} from '@exadel/esl/modules/esl-utils/decorators/listen';

class RefreshOnLoginMixin extends ESLMixinElement {
  static is = 'refresh-on-login';

  @listen({event: 'auth:api:logged', target: window, once: true})
  public onLogin(): void {
    this.$$fire('esl:refresh');
  }
}

export default {
  initialize: () => RefreshOnLoginMixin.register()
};
